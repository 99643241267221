import axios from "axios";
import Cookies from "js-cookie";
import { CategoryItem, CategoryListResponse } from "../types/category";
import { ChangeLogDataType } from "../types/change-log";
import { ThemeListResponse } from "../types/theme";
import { CustomWidgetGet, CustomWidgetItem } from "../types/widget";
import { ChangeLogItemType } from "./../types/change-log.d";
import { ProductDetailsType, ProductItemGet } from "./../types/product.d";
import { CustomWidgetDeleteRes, CustomWidgetPost } from "./../types/widget.d";
import { adminTokenCookieName, envs } from "./constants";

interface Pagination {
	perPage?: number;
	currentPage?: number;
	search?: string;
	status?: string;
}

export const getWidgetList = (): Promise<CustomWidgetGet> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.get(
				process.env.REACT_APP_BUILDER_URL + "widget?perPage=100",
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getWidgetItem = (id: string): Promise<CustomWidgetItem> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.get(
				process.env.REACT_APP_BUILDER_URL + "widget/" + id,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * @desc update widget item
 * @param widgetItem
 * @returns response data
 * @author Azher Uddin <https://github.com/AzherUddin617>
 */
export const updateWidgetItem = (
	widgetItem: CustomWidgetItem
): Promise<CustomWidgetItem> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.patch(
				process.env.REACT_APP_BUILDER_URL + "widget/" + widgetItem._id,
				widgetItem,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * @desc to delete widget item
 * @param widgetId
 * @returns response data
 * @author Azher Uddin <https://github.com/AzherUddin617>
 */
export const deleteWidgetItem = (
	widgetId: string
): Promise<CustomWidgetDeleteRes> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.delete(
				process.env.REACT_APP_BUILDER_URL + "widget/" + widgetId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * @desc to create widget item
 * @param widgetItem - widget settings
 * @returns response data
 * @author Azher Uddin <https://github.com/AzherUddin617>
 */
export const createWidgetItem = (
	widgetItem: CustomWidgetPost
): Promise<CustomWidgetItem> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.post(
				process.env.REACT_APP_BUILDER_URL + "widget",
				widgetItem,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

/**
 * @desc to get category items
 * @returns category items
 * @author Azher Uddin <https://github.com/AzherUddin617>
 * @date 2022-12-12 16:57:52
 */
export const getCategoryItems = (): Promise<CategoryItem[]> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.get(
				process.env.REACT_APP_BASE_URL + "category"
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getProductsBySkus = (
	skus: string[]
): Promise<ProductDetailsType[]> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(
				envs.frontendApiUrl + "product/storefront/get-by-skus",
				{ skus }
			);

			resolve(res.data?.data || []);
		} catch (error) {
			reject(error);
		}
	});
};

export const getProductByIds = (
	ids: number[],
	shopApiKey: string
): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(
				envs.frontendApiUrl + "product/storefront/get-by-ids",
				{ ids },
				{
					headers: {
						"x-api-key": shopApiKey,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getProductsByLogic = (
	logics: any,
	shopApiKey: string
): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(
				envs.frontendApiUrl + "product/storefront/get-by-logic",
				logics,
				{
					headers: {
						"x-api-key": shopApiKey,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPageChangeLogs = (
	pageId: string,
	currentPage = 1,
	perPage = 10
): Promise<ChangeLogDataType> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.get(
				process.env.REACT_APP_BUILDER_URL +
					"page/histories/" +
					pageId +
					`?currentPage=${currentPage}&perPage=${perPage}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getPageChangeLogById = (
	logId: string
): Promise<ChangeLogItemType> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.get(
				process.env.REACT_APP_BUILDER_URL +
					"page/histories/get-one/" +
					logId,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const setPageActive = (
	pageId: string,
	active: boolean
): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.patch(
				process.env.REACT_APP_BUILDER_URL + "page/active/" + pageId,
				{
					active: active,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

interface CategoryProps extends Pagination {
	parentId?: string | number | null;
	status?: "active" | "inactive" | "";
	parent?: boolean;
	isFeatured?: boolean;
}

export const getCategoryList = ({
	perPage = 10,
	currentPage = 1,
	search,
	parentId,
	status,
	parent,
	isFeatured,
}: CategoryProps): Promise<CategoryListResponse> => {
	let query = "";
	if (parentId !== undefined) query += "&parentId=" + parentId;
	if (status !== undefined) query += "&status=" + status;
	if (parent !== undefined) query += "&parent=" + parent;
	if (isFeatured !== undefined) query += "&isFeatured=" + isFeatured;
	if (search !== undefined) query += "&search=" + search;

	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.get<CategoryListResponse>(
				`${envs.frontendApiUrl}category?perPage=${perPage}&currentPage=${currentPage}${query}`,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const getProductList = ({
	perPage = 20,
	currentPage = 1,
	search,
	parentId,
	status,
	parent,
	isFeatured,
}: CategoryProps): Promise<ProductItemGet[]> => {
	let query = "";
	if (parentId !== undefined) query += "&parentId=" + parentId;
	if (status !== undefined) query += "&status=" + status;
	if (parent !== undefined) query += "&parent=" + parent;
	if (isFeatured !== undefined) query += "&isFeatured=" + isFeatured;
	if (search !== undefined) query += "&search=" + search;

	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_BASE_URL}product?perPage=${perPage}&currentPage=${currentPage}${query}`,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);
			resolve(res.data?.data || []);
		} catch (error) {
			reject(error);
		}
	});
};

export const getBrandList = ({
	perPage = 10,
	currentPage = 1,
	search,
	status,
}: Pagination): Promise<CategoryListResponse> => {
	let query = "";
	if (status !== undefined) query += "&status=" + status;
	if (search !== undefined) query += "&search=" + search;

	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.get<CategoryListResponse>(
				`${envs.frontendApiUrl}brand?perPage=${perPage}&currentPage=${currentPage}${query}`,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

// Widget Create: {{apiUrl}}/api/v1/widget
export const createWidget = (data: any) => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.post(`${envs.frontendApiUrl}widget`, data, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			});
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const reqMakeHomePage = (
	pageId: string,
	mode: "desktop" | "mobile" | "all"
): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		const token = Cookies.get(adminTokenCookieName);

		try {
			const res = await axios.patch(
				process.env.REACT_APP_BUILDER_URL +
					"page/default-home-page/" +
					pageId,
				{
					screenMode: mode,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

// get all themes: {{apiUrl}}/api/v1/theme?currentPage=1&perPage=10&status=active
export const getThemeList = ({
	perPage = 10,
	currentPage = 1,
	search,
	status,
	shopType,
}: Pagination & {
	shopType?: number;
}): Promise<ThemeListResponse> => {
	let query = "";
	if (status !== undefined) query += "&status=" + status;
	if (search !== undefined) query += "&search=" + search;
	if (shopType !== undefined) query += "&shopType=" + shopType;

	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.get<ThemeListResponse>(
				`${envs.frontendApiUrl}theme?perPage=${perPage}&currentPage=${currentPage}${query}`,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);
			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

// change shop theme: {{apiUrl}}/api/v1/shop/theme
export const changeShopTheme = (data: any): Promise<any> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.patch(
				`${envs.frontendApiUrl}shop/theme`,
				data,
				{
					headers: {
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

// get shop types: {{apiUrl}}/api/v1/shop-type
export const getShopTypes = (): Promise<any[]> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await axios.get(`${envs.frontendApiUrl}shop-type`, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			});

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};
