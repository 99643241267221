// import {
// 	FileLoader,
// 	UploadAdapter,
// } from "@ckeditor/ckeditor5-upload/src/filerepository";
import CKEditorBuild from "./CKEditorBuild";

interface tinyProps {
	value?: string;
	onEventChangeHandler: (evt: any, editor?: any) => void;
}

const TinyMCE = ({ value, onEventChangeHandler }: tinyProps) => {
	return (
		<CKEditorBuild
			value={value || ""}
			onEventChangeHandler={onEventChangeHandler}
		/>
	);

	// const uploadAdapter = (loader: FileLoader): UploadAdapter => {
	// 	return {
	// 		upload: () => {
	// 			return new Promise(async (resolve, reject) => {
	// 				try {
	// 					const file = await loader.file;

	// 					if (file) {
	// 						const formData = new FormData();

	// 						formData.append("file", file);

	// 						console.log({
	// 							formData1: Object.fromEntries(formData),
	// 						});

	// 						axios
	// 							.post(
	// 								`${process.env.REACT_APP_MEDIA_URL}`,
	// 								formData,
	// 								{
	// 									headers: {
	// 										"Content-Type":
	// 											"multipart/form-data",
	// 										Authorization: `Bearer ${Cookies.get(
	// 											"bitcommerz-sass-admin-token"
	// 										)}`,
	// 									},
	// 								}
	// 							)
	// 							.then(({ data }) => {
	// 								console.log("data =>", data);
	// 								resolve({
	// 									default: `${process.env.REACT_APP_IMAGE_URL}${data.path}`,
	// 								});
	// 							});
	// 					}
	// 				} catch (error) {
	// 					reject("Hello");
	// 				}
	// 			});
	// 		},
	// 		abort: () => {},
	// 	};
	// };

	// function uploadPlugin(editor: Editor) {
	// 	editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
	// 		return uploadAdapter(loader);
	// 	};
	// }

	//   toolbar: 'bold italic underline | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | code |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | undo redo',

	// return <CKTextEditor setValue={onEventChangeHandler} value={value || ""} />;

	// return (
	// 	<div>
	// 		<CKEditor
	// 			editor={ClassicEditor}
	// 			data={value}
	// 			onReady={(editor) => {
	// 				// You can store the "editor" and use when it is needed.
	// 				console.log("Editor is ready to use!", editor);
	// 			}}
	// 			config={
	// 				{
	// 					toolbar: [
	// 						"heading",
	// 						"|",
	// 						"bold",
	// 						"italic",
	// 						"underline",
	// 						"strikethrough",
	// 						"subscript",
	// 						"superscript",
	// 						"|",
	// 						"fontfamily",
	// 						"fontsize",
	// 						"fontColor",
	// 						"fontBackgroundColor",
	// 						"|",
	// 						"alignment",
	// 						"|",
	// 						"numberedList",
	// 						"bulletedList",
	// 						"|",
	// 						"indent",
	// 						"outdent",
	// 						"|",
	// 						"link",
	// 						"blockQuote",
	// 						"insertTable",
	// 						"mediaEmbed",
	// 						"|",
	// 						"undo",
	// 						"redo",
	// 						"|",
	// 						"code",
	// 						"codeBlock",
	// 						"|",
	// 						"horizontalLine",
	// 						"specialCharacters",
	// 						"|",
	// 						"removeFormat",
	// 						"sourceEditing",
	// 					],
	// 					mediaEmbed: {
	// 						previewsInData: true,
	// 					},
	// 					extraPlugins: [uploadPlugin],

	// 					heading: {
	// 						options: [
	// 							{
	// 								model: "paragraph",
	// 								title: "Paragraph",
	// 								class: "ck-heading_paragraph",
	// 							},
	// 							{
	// 								model: "heading1",
	// 								view: "h1",
	// 								title: "Heading 1",
	// 								class: "ck-heading_heading1",
	// 							},
	// 							{
	// 								model: "heading2",
	// 								view: "h2",
	// 								title: "Heading 2",
	// 								class: "ck-heading_heading2",
	// 							},
	// 							{
	// 								model: "heading3",
	// 								view: "h3",
	// 								title: "Heading 3",
	// 								class: "ck-heading_heading3",
	// 							},
	// 							{
	// 								model: "heading4",
	// 								view: "h4",
	// 								title: "Heading 4",
	// 								class: "ck-heading_heading4",
	// 							},
	// 							{
	// 								model: "heading5",
	// 								view: "h5",
	// 								title: "Heading 5",
	// 								class: "ck-heading_heading5",
	// 							},
	// 							{
	// 								model: "heading6",
	// 								view: "h6",
	// 								title: "Heading 6",
	// 								class: "ck-heading_heading6",
	// 							},
	// 						],
	// 					},
	// 					link: {
	// 						// addTargetToExternalLinks: true,
	// 						// allowCreatingEmptyLinks: true,
	// 						defaultProtocol: "https://",
	// 						decorators: {
	// 							isExternal: {
	// 								mode: "manual",
	// 								label: "Open in a new tab",
	// 								attributes: {
	// 									target: "_blank",
	// 								},
	// 							},
	// 							isExtendedWithNoFollow: {
	// 								mode: "manual",
	// 								label: "Nofollow",
	// 								attributes: {
	// 									rel: "noopener noreferrer nofollow",
	// 								},
	// 							},
	// 							isDownloadable: {
	// 								mode: "manual",
	// 								label: "Downloadable",
	// 								attributes: {
	// 									download: "file.png",
	// 								},
	// 							},
	// 						},
	// 					},
	// 				} as any
	// 			}
	// 			onChange={(event, editor: any) => {
	// 				const data = editor?.getData();
	// 				onEventChangeHandler(data, editor);
	// 			}}
	// 			onBlur={(event, editor) => {
	// 				console.log("Blur.", editor);
	// 			}}
	// 			onFocus={(event, editor) => {
	// 				console.log("Focus.", editor);
	// 			}}
	// 		/>
	// 	</div>
	// );
};

export default TinyMCE;
