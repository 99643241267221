import {
	ButtonCard,
	GridCard,
	SpacingCard,
	TimeoutCard,
} from "../components/Widgets";
import Campaign, {
	FilterProducts,
	RandomBanners,
	ServicesBanner,
	SmallWideBanner,
	ThreeBannersProducts,
	TrendingProducts,
	VideoBanner,
} from "../components/Widgets/Banner";
import BestSellingProducts from "../components/Widgets/BestSellingProducts";
import Brands from "../components/Widgets/Brands";
import Category from "../components/Widgets/Category";
import ComtentEditor from "../components/Widgets/ContentEditor";
import HighlightedShop from "../components/Widgets/HighlightedShop";
import InDemand from "../components/Widgets/InDemand";
import MainSlider from "../components/Widgets/MainSlider";
import NewArrival from "../components/Widgets/NewArrival";
import OneCollumAdd from "../components/Widgets/OneCollumAdd";
import PopulerProduct from "../components/Widgets/PopulerProduct";
import ProductRow from "../components/Widgets/ProductRow";
import ProductSlider from "../components/Widgets/ProductSlider";
import ProductsRowLimit from "../components/Widgets/ProductsRowLimit";
import SingleProduct from "../components/Widgets/SingleProducts";
import ThreeCollumsImages from "../components/Widgets/ThreeCollumsImages";
import Title from "../components/Widgets/Title";
import TodaysDeal from "../components/Widgets/TodaysDeal";
import TwoCollumAdd from "../components/Widgets/TwoCollumAdd";
import GridWidgets from "../components/storefront/GridWidgets";
import ProductRowPreview from "../components/storefront/ProductRow";
import SectionHeader from "../components/storefront/SectionHeader";
import ShowBannersSection, {
	ShowBannersSlider,
} from "../components/storefront/ShowBannersSection";
import { LogicalOrderTypes } from "./../components/LogicBuilder/LogicalBuilder/index";
import { LinkData } from "./../components/widgetFrom/LinkForm/index";

export interface Image {
	image: string;
	title: string;
	description?: string;
	link: LinkData;
}
export interface LogicalObj {
	id: number;
	limit: string;
	name?: string;
	avatar?: string;
}
export interface Logical {
	category: LogicalObj[];
	categoryOrder?: LogicalOrderTypes;
	brand: LogicalObj[];
	brandOrder?: LogicalOrderTypes;
	seller: LogicalObj[];
	sellerOrder?: LogicalOrderTypes;
	campaign: LogicalObj[];
	campaignOrder?: LogicalOrderTypes;
	isFeatured?: boolean;

	any?: boolean;
	anyOrder?: LogicalOrderTypes;
}
export interface PageCategory {
	id: number;
	name: string;
	slug: string;
}
export interface IndemandObj {
	image: string;
	title: string;
	subTitle: string;
	link: LinkData;
}

export interface RenderWidgetProps {
	widget?: Widget;
	onWidgetDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
}

export interface Widget {
	images?: string;
	wType?: "widgets" | "sidebar" | "topbar";
	name: string;
	id?: string;
	monarchmart_sku?: string;
	title?: string;
	link?: LinkData;
	active: boolean;
	Component?: React.FC;
	RenderWidget?: React.FC<RenderWidgetProps>;
	forPageTypes?: string[];
	data?: {
		images?: Image[];
		selectedCampaign?: PageCategory | null;
		showTimer?: boolean;
		widgets?: Widget[];
		setting?: {
			custom?: number[];
			logical?: Logical;
			productType?: "";
		};
		settings?: any;
		products?: [];
		featuredProduct?: {};
		shops?: [];
		bestSellingProducts?: {
			products: [];
			featuredImage: {
				image: "";
				title: "";
				url: LinkData;
				target: "";
			};
		};
		singleProductSlider?: {
			products: [];
		};
		electronicsApplience?: {
			productTypes: [];
			featuredImage: {
				image: "";
				title: "";
				url: "";
				target: "";
			};
		};
		sellerProfile?: {
			campaignImages: [];
			coverImage: "";
			profileImage: "";
		};
		content?: "";
		rowLimit?: string;
		isSlider?: boolean;
		backgroundColor?: string;
		inDemandObj?: {
			inDemandObjOne?: {
				image: "";
				title: "";
				subTitle: "";
				link: {};
			};
			inDemandObjTwo?: {
				image: "";
				title: "";
				subTitle: "";
				link: {};
			};
			inDemandObjThree?: {
				image: "";
				title: "";
				subTitle: "";
				link: {};
			};
			inDemandObjFour?: {
				image: "";
				title: "";
				subTitle: "";
				link: {};
			};
		};
		rightImages?: {
			rightImageOne?: {
				image: "";
				title: "";
				link: {};
			};
			rightImageTwo?: {
				image: "";
				title: "";
				link: {};
			};
			rightImageThree?: {
				image: "";
				title: "";
				link: {};
			};
		};
	};
	imageLimit?: number;
	featuredImage?: string;
	fetchuredImageUrl?: string | LinkData;
	fetcturedImagePosition?: string;
	fetcturedImagelinkTarget?: string;
	category?: any[];
	brandList?: any[];
	sellerList?: number[];
	productLimit?: string;
	rightImages?: Image[];
	rightImagesPosition?: string;
}

export const widgetNames = {
	lastVisitedProducts: "last-visited-products",
	infiniteProductRow: "infinite-product-row",
	smallWideBanner: "small-wide-banner",
	banners: "banners",
	trendingProducts: "trending-products",
	filterProducts: "filter-products",
	randomSizeBanners: "random-size-banners",
	threeBannerProducts: "three-banner-products",
	videoSection: "video-section",
	services: "services",
	padding: "padding",
	timeout: "timeout",
	grid: "grid",

	title: "title",
	slider: "slider",
	banner: "banner",
	"3-collum-images": "3-collum-images",
	"populer-product": "populer-product",
	"product-slider": "product-slider",
	"product-row": "product-row",
	categories: "categories",
	"one-collum-add": "one-collum-add",
	"two-collum-add": "two-collum-add",
	"new-arrival": "new-arrival",
	"todays-deal": "todays-deal",
	"highlighted-shop": "highlighted-shop",
	"best-selling": "best-selling",
	"single-product": "single-product",
	"content-editor": "content-editor",
	"product-row-limit": "product-row-limit",
	"in-demand": "in-demand",
	"brand-list": "brand-list",
	button: "button",
};

export const supportGridWidgets = [
	widgetNames.banners,
	widgetNames.banner,
	widgetNames["content-editor"],
	widgetNames.timeout,
	widgetNames.videoSection,
];

export const widgetData = {
	title: {
		name: widgetNames.title,
		active: true,
		title: "",
		link: {} as LinkData,
		Component: Title,
		RenderWidget: SectionHeader,
	},
	slider: {
		name: widgetNames.slider,
		active: true,
		data: {},
		Component: MainSlider,
		RenderWidget: ShowBannersSlider,
	},
	banner: {
		name: widgetNames.banner,
		title: "title here",
		active: true,
		data: {},
		Component: Campaign,
	},
	"3-collum-images": {
		name: widgetNames["3-collum-images"],
		active: true,
		data: {},
		Component: ThreeCollumsImages,
		RenderWidget: ShowBannersSection,
	},
	"populer-product": {
		name: widgetNames["populer-product"],
		title: "title here",
		active: true,
		imageLimit: 1,
		featuredImage: "",
		fetchuredImageUrl: "",
		fetcturedImagePosition: "left",
		fetcturedImagelinkTarget: "",
		data: {},
		Component: PopulerProduct,
	},
	categories: {
		name: widgetNames.categories,
		active: true,
		category: [],
		Component: Category,
	},
	"product-row": {
		name: widgetNames["product-row"],
		active: true,
		productLimit: "",
		Component: ProductRow,
		forPageTypes: ["category", "brand"],
	},
	"one-collum-add": {
		name: "one-collum-add",
		title: "",
		active: true,
		data: {},
		Component: OneCollumAdd,
		RenderWidget: ShowBannersSection,
	},
	"two-collum-add": {
		name: widgetNames["two-collum-add"],
		title: "",
		active: true,
		data: {},
		Component: TwoCollumAdd,
		RenderWidget: ShowBannersSection,
	},
	"brand-list": {
		name: widgetNames["brand-list"],
		active: true,
		brandList: [],
		Component: Brands,
	},
	"new-arrival": {
		name: widgetNames["new-arrival"],
		title: "",
		active: true,
		featuredImage: "",
		fetchuredImageUrl: "",
		rightImages: [],
		rightImagesPosition: "right",
		data: {},
		Component: NewArrival,
	},
	"product-slider": {
		name: widgetNames["product-slider"],
		title: "Products List",
		active: true,
		data: {},
		Component: ProductSlider,
		RenderWidget: ProductRowPreview,
	},
	"todays-deal": {
		name: widgetNames["todays-deal"],
		title: "",
		active: true,
		data: {},
		Component: TodaysDeal,
	},
	"highlighted-shop": {
		name: widgetNames["highlighted-shop"],
		title: "",
		active: true,
		data: {},
		Component: HighlightedShop,
	},
	"best-selling": {
		name: widgetNames["best-selling"],
		title: "",
		active: true,
		data: {},
		Component: BestSellingProducts,
	},
	"single-product": {
		name: widgetNames["single-product"],
		title: "",
		active: true,
		data: {},
		Component: SingleProduct,
	},
	"content-editor": {
		name: widgetNames["content-editor"],
		title: "",
		active: true,
		data: {},
		Component: ComtentEditor,
	},
	"product-row-limit": {
		name: widgetNames["product-row-limit"],
		title: "",
		active: true,
		data: {},
		Component: ProductsRowLimit,
	},
	"in-demand": {
		name: widgetNames["in-demand"],
		title: "",
		active: true,
		data: {},
		Component: InDemand,
	},
	[widgetNames.lastVisitedProducts]: {
		name: widgetNames.lastVisitedProducts,
		title: "Last Visited Products",
		active: true,
		data: {},
		Component: ProductRow,
	},
	[widgetNames.smallWideBanner]: {
		name: widgetNames.smallWideBanner,
		title: "Small & Wide Banner",
		active: true,
	},
	[widgetNames.banners]: {
		name: widgetNames.banners,
		title: "Banners",
		active: true,
		Component: ThreeCollumsImages,
		RenderWidget: ShowBannersSection,
	},
	[widgetNames.trendingProducts]: {
		name: widgetNames.trendingProducts,
		title: "Trending Products",
		active: true,
		Component: TrendingProducts,
	},
	[widgetNames.filterProducts]: {
		name: widgetNames.filterProducts,
		title: "Filter Tab Products",
		active: true,
		Component: FilterProducts,
	},
	[widgetNames.randomSizeBanners]: {
		name: widgetNames.randomSizeBanners,
		title: "Random Size Banners",
		active: true,
		Component: RandomBanners,
	},
	[widgetNames.threeBannerProducts]: {
		name: widgetNames.threeBannerProducts,
		title: "Three Banner Products",
		active: true,
		Component: ThreeBannersProducts,
	},
	[widgetNames.lastVisitedProducts]: {
		name: widgetNames.lastVisitedProducts,
		title: "Last Visited Products",
		active: true,
		data: {} as any,
		Component: ProductsRowLimit,
	},
	[widgetNames.smallWideBanner]: {
		name: widgetNames.smallWideBanner,
		title: "Small & Wide Banner",
		active: true,
		Component: SmallWideBanner,
	},
	[widgetNames.padding]: {
		name: widgetNames.padding,
		title: "Spacing",
		active: true,
		Component: SpacingCard,
	},
	[widgetNames.videoSection]: {
		name: widgetNames.videoSection,
		title: "Video Section",
		active: true,
		Component: VideoBanner,
	},
	[widgetNames.timeout]: {
		name: widgetNames.timeout,
		title: "Timeout",
		active: true,
		Component: TimeoutCard,
	},
	[widgetNames.grid]: {
		name: widgetNames.grid,
		title: "Grid",
		active: true,
		Component: GridCard,
		RenderWidget: GridWidgets,
	},
	[widgetNames.button]: {
		name: widgetNames.button,
		title: "Button",
		active: true,
		forPageTypes: ["landing-page"],
		Component: ButtonCard,
	},
	[widgetNames.services]: {
		name: widgetNames.services,
		title: "Services",
		active: true,
		data: {},
		Component: ServicesBanner,
	},
	// [widgetNames.infiniteProductRow]: {
	//     name: widgetNames.infiniteProductRow,
	//     title: 'Infinite Product Row',
	//     active: true,
	//     data: {}
	// }
};

export const widgets: Widget[] = [
	widgetData.title,
	widgetData.slider,
	// widgetData.banner,
	widgetData[widgetNames.banners],
	// widgetData["3-collum-images"],
	widgetData["populer-product"],
	widgetData["product-slider"],
	widgetData["product-row"],
	widgetData[widgetNames.grid],
	widgetData.categories,
	// widgetData["one-collum-add"],
	// widgetData["two-collum-add"],
	// widgetData["brand-list"],
	// widgetData["new-arrival"],
	// widgetData["todays-deal"],
	// widgetData["highlighted-shop"],
	// widgetData["best-selling"],
	widgetData["single-product"],
	widgetData["content-editor"],
	// widgetData["product-row-limit"],
	// widgetData["in-demand"],
	// widgetData[widgetNames.lastVisitedProducts],
	// widgetData[widgetNames.infiniteProductRow],
	// widgetData[widgetNames.smallWideBanner],
	widgetData[widgetNames.trendingProducts],
	// widgetData[widgetNames.filterProducts],
	// widgetData[widgetNames.randomSizeBanners],
	widgetData[widgetNames.threeBannerProducts],
	widgetData[widgetNames.padding],
	widgetData[widgetNames.videoSection],
	widgetData[widgetNames.timeout],
	widgetData[widgetNames.button],
	widgetData[widgetNames.services],
];

export const webWidgets: Widget[] = [
	widgetData.title,
	widgetData.slider,
	// widgetData.banner,
	widgetData[widgetNames.banners],
	// widgetData["3-collum-images"],
	widgetData["populer-product"],
	widgetData["product-slider"],
	widgetData["product-row"],
	widgetData[widgetNames.grid],
	widgetData.categories,
	// widgetData["one-collum-add"],
	// widgetData["two-collum-add"],
	// widgetData["new-arrival"],
	// widgetData["todays-deal"],
	// widgetData["highlighted-shop"],
	// widgetData["best-selling"],
	widgetData["single-product"],
	widgetData["content-editor"],
	widgetData["product-row-limit"],
	// widgetData["in-demand"],
	// widgetData[widgetNames.lastVisitedProducts],
	// widgetData[widgetNames.infiniteProductRow],
	// widgetData[widgetNames.smallWideBanner],
	widgetData[widgetNames.trendingProducts],
	// widgetData[widgetNames.filterProducts],
	// widgetData[widgetNames.randomSizeBanners],
	widgetData[widgetNames.threeBannerProducts],
	widgetData[widgetNames.padding],
	widgetData[widgetNames.videoSection],
	widgetData[widgetNames.timeout],
	// widgetData["brand-list"],
	widgetData[widgetNames.button],
	widgetData[widgetNames.services],
];

export const mobileWidgets: Widget[] = [
	widgetData.title,
	widgetData.slider,
	// widgetData.banner,
	widgetData[widgetNames.banners],
	widgetData["populer-product"],
	widgetData[widgetNames.grid],
	widgetData.categories,
	widgetData["product-slider"],
	widgetData["product-row"],
	// widgetData["one-collum-add"],
	// widgetData["two-collum-add"],
	// widgetData["brand-list"],
	// widgetData["new-arrival"],
	// widgetData["todays-deal"],
	// widgetData["best-selling"],
	widgetData["product-row-limit"],
	// widgetData[widgetNames.smallWideBanner],
	widgetData[widgetNames.trendingProducts],
	// widgetData[widgetNames.filterProducts],
	// widgetData[widgetNames.randomSizeBanners],
	widgetData[widgetNames.threeBannerProducts],
	widgetData[widgetNames.padding],
	widgetData[widgetNames.videoSection],
	widgetData[widgetNames.timeout],
	widgetData[widgetNames.button],
	widgetData[widgetNames.services],
];
