import { FaArrowRight } from "react-icons/fa";
import { getSectionStyle } from "../../../utils";
import { RenderWidgetProps } from "../../../widgetList/widgetList";
import ShowMoreButton from "../ShowMoreButton";
import classes from "./SectionHeader.module.css";

const SectionHeader = ({ widget }: RenderWidgetProps) => {
	const title = widget?.title || "Title";

	const settings = widget?.data?.settings || {};

	const align = settings?.align || "left";
	const btnType = settings?.btnType || "all";

	console.log("widget", widget);

	return (
		<div
			className={classes.sectionHeader}
			style={getSectionStyle(settings)}>
			<div className={"no-container"}>
				<div
					className={classes.contents}
					style={{
						textAlign: align,
						...(align === "center" && { justifyContent: "center" }),
						...(align === "right" && {
							justifyContent: "flex-end",
						}),
					}}>
					<div className={classes.left}>
						<h2 className={classes.title}>{title}</h2>
					</div>

					<div className={classes.right}>
						{btnType === "arrow" ? (
							<div className={classes.arrow}>
								<FaArrowRight className={classes.left} />
							</div>
						) : (
							<ShowMoreButton type={btnType} text="more" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SectionHeader;
