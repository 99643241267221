import React, { FC, UIEvent, useEffect, useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import "./imagePopUp.css";

import { useDispatch, useSelector } from "react-redux";
import {
	closeImagesPopUp,
	getChoosenImages,
} from "../../features/ShowImagePopUp/showImagePopUp";

import { RootState } from "../../app/store";
import { fetchImagesModule, uploadImage } from "../../modules/mediaManager";
import { PagePagination } from "../../pages/MediaPage";

export interface PopUpImage {
	id: number;
	url: string;
}

const ImagePopUp: FC = () => {
	const showImagePopUpType = useSelector(
		(state: RootState) => state.showImagePopUp.type
	);
	const permissions = useSelector(
		(state: RootState) => state.authPermission.permissions
	);
	const dispatch = useDispatch();

	const [images, setImages] = useState<PopUpImage[]>([]);
	const [chooseItemId, setChooseItemId] = useState(0);
	const [chooseItem, setChooseItem] = useState<boolean>(false);
	const [imagesForUpload, setImagesForUpload] = useState<any[]>([]);
	const [choosenImages, setChoosenImages] = useState<PopUpImage[]>([]);
	const [choosenImage, setChoosenImage] = useState<PopUpImage[]>([]);
	const [choosenImagesForCover, setChoosenImagesForCover] = useState<
		PopUpImage[]
	>([]);
	const [choosenImagesForProfile, setChoosenImagesForProfile] = useState<
		PopUpImage[]
	>([]);
	const [indemandImageOne, setIndemandImageOne] = useState<PopUpImage[]>([]);
	const [indemandImageTwo, setIndemandImageTwo] = useState<PopUpImage[]>([]);
	const [indemandImageThree, setIndemandImageThree] = useState<PopUpImage[]>(
		[]
	);
	const [indemandImageFour, setIndemandImageFour] = useState<PopUpImage[]>(
		[]
	);
	const [featuredImage, setFeatiredImage] = useState<PopUpImage[]>([]);
	const [rightImageOne, setRightImageOne] = useState<PopUpImage[]>([]);
	const [rightImageTwo, setRightImageTwo] = useState<PopUpImage[]>([]);
	const [rightImageThree, setRightImageThree] = useState<PopUpImage[]>([]);
	const [pagePagination, setPagePagination] = useState<PagePagination>({
		total: 0,
		lastPage: 1,
		currentPage: 1,
	});

	useEffect(() => {
		getImages(pagePagination);
	}, []);

	const getImages = async (pagination: PagePagination) => {
		try {
			const imageList = await fetchImagesModule(pagination);
			pagination?.total === 0
				? setImages(imageList.data)
				: setImages(images.concat(imageList.data));
			if (imageList?.totalResult) {
				const { data, ...pagination } = imageList;
				setPagePagination({
					...pagination,
					lastPage: pagination.totalPage,
					total: pagination.totalResult,
				});
			}
		} catch (error) {
			throw error;
		}
	};

	const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
		const target = e.target as HTMLDivElement;

		if (
			pagePagination.currentPage < pagePagination.lastPage &&
			target.scrollTop + target.clientHeight >= target.scrollHeight
		) {
			const updatedPagination = {
				...pagePagination,
				currentPage: pagePagination.currentPage + 1,
			};
			getImages(updatedPagination);
			setPagePagination(updatedPagination);
		}
	};
	const handleChooseImage = (image: any) => {
		setChooseItemId(image?.id); // eslint-disable-next-line array-callback-return
		images.map((image) => {
			if (image?.id === chooseItemId) {
				setChooseItem(true);
			}
		});

		const _choosenImages = [...choosenImages];
		_choosenImages.push(image);

		if (showImagePopUpType === "cover") {
			setChoosenImagesForCover(_choosenImages);
		} else if (showImagePopUpType === "single") {
			setChoosenImage(_choosenImages);
		} else if (showImagePopUpType === "profile") {
			setChoosenImagesForProfile(_choosenImages);
		} else if (showImagePopUpType === "indemand-one") {
			setIndemandImageOne(_choosenImages);
		} else if (showImagePopUpType === "indemand-two") {
			setIndemandImageTwo(_choosenImages);
		} else if (showImagePopUpType === "indemand-three") {
			setIndemandImageThree(_choosenImages);
		} else if (showImagePopUpType === "indemand-four") {
			setIndemandImageFour(_choosenImages);
		} else if (showImagePopUpType === "featured") {
			setFeatiredImage(_choosenImages);
		} else if (showImagePopUpType === "right-one") {
			setRightImageOne(_choosenImages);
		} else if (showImagePopUpType === "right-two") {
			setRightImageTwo(_choosenImages);
		} else if (showImagePopUpType === "right-three") {
			setRightImageThree(_choosenImages);
		} else {
			setChoosenImages(_choosenImages);
		}
	};
	const handleImagesForUpload = async (e: React.ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		const items = target.files as FileList;
		const arrayOfItems = Object.values(items);
		await setImagesForUpload(arrayOfItems);
	};
	const handleUploadImage = () => {
		// eslint-disable-next-line array-callback-return
		imagesForUpload.map(async (item) => {
			const uploadedImages = await uploadImage(item);
			if (uploadedImages) {
				getImages({
					total: 0,
					lastPage: 1,
					currentPage: 1,
				});
			}
		});
	};
	const handleInsertImages = () => {
		if (showImagePopUpType === "cover") {
			dispatch(getChoosenImages(choosenImagesForCover));
		} else if (showImagePopUpType === "profile") {
			dispatch(getChoosenImages(choosenImagesForProfile));
		} else if (showImagePopUpType === "single") {
			dispatch(getChoosenImages(choosenImage));
		} else if (showImagePopUpType === "indemand-one") {
			dispatch(getChoosenImages(indemandImageOne));
		} else if (showImagePopUpType === "indemand-two") {
			dispatch(getChoosenImages(indemandImageTwo));
		} else if (showImagePopUpType === "indemand-three") {
			dispatch(getChoosenImages(indemandImageThree));
		} else if (showImagePopUpType === "indemand-four") {
			dispatch(getChoosenImages(indemandImageFour));
		} else if (showImagePopUpType === "featured") {
			dispatch(getChoosenImages(featuredImage));
		} else if (showImagePopUpType === "right-one") {
			dispatch(getChoosenImages(rightImageOne));
		} else if (showImagePopUpType === "right-two") {
			dispatch(getChoosenImages(rightImageTwo));
		} else if (showImagePopUpType === "right-three") {
			dispatch(getChoosenImages(rightImageThree));
		} else {
			dispatch(getChoosenImages(choosenImages));
		}
		dispatch(closeImagesPopUp());
	};
	const deleteChoosenImage = (id: number) => {
		const filter = choosenImages.filter((item) => item.id !== id);
		setChoosenImages(filter);
	};
	return (
		<div className="image__popup--container">
			<div className="image__popup--row">
				<div className="image__popup--col">
					<div
						className="image__popup--wrapper"
						onScroll={handlePageScroll}>
						<div className="p-6">
							<div className="grid grid-cols-5 gap-6 pb-32">
								{images?.map((image: PopUpImage) => {
									return (
										<div
											key={image?.id}
											className={`w-full h-full p-2 shadow-lg cursor-pointer ${
												chooseItemId === image?.id
													? "bg-blue-300"
													: "bg-white"
											}`}
											onClick={() =>
												handleChooseImage(image)
											}>
											<img
												className="w-full h-40 aspect-auto object-contain object-center bg-gray-100"
												src={image?.url}
												alt=""
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div
						className="image__popup--close-btn"
						onClick={() => dispatch(closeImagesPopUp())}>
						<CloseIcon />
					</div>
					<div className="absolute w-full h-32 bg-white bottom-0 right-0 left-0 grid grid-cols-2 gap-2 p-2 shadow">
						{permissions.includes("create_media") ? (
							<div className="bg-gray-200 grid grid-cols-2">
								<div className="w-full flex items-center justify-center p-4">
									<input
										type="file"
										accept="image/png, image/jpeg, image/svg"
										multiple
										onChange={handleImagesForUpload}
									/>
								</div>
								<div className="w-full flex items-center justify-center p-4">
									<button
										className="capitalize bg-green-500 px-8 py-3 text-white tracking-wider rounded-md shadow-lg w-full"
										onClick={handleUploadImage}>
										upload
									</button>
								</div>
							</div>
						) : (
							<div className="bg-gray-200 grid grid-cols-2"></div>
						)}
						<div className="bg-gray-200 grid grid-cols-3">
							<div className="col-span-2 flex items-center gap-2 mx-2 pr-1 overflow-x-auto w-full">
								{choosenImages.map((image) => {
									return (
										<div className="relative">
											<img
												key={image?.id}
												className="w-16 h-16 bg-white rounded-md shadow-md mr-4 last:mr-2 aspect-auto object-contain object-center"
												src={image?.url}
												alt=""
											/>
											<div
												className="image__popup--close-btn-two"
												onClick={() =>
													deleteChoosenImage(
														image?.id
													)
												}>
												<CloseIcon />
											</div>
										</div>
									);
								})}
							</div>
							<div className="flex items-center pr-4 pl-2">
								<button
									className="capitalize bg-blue-500 px-8 py-3 text-white tracking-wider rounded-md shadow-lg w-full"
									onClick={handleInsertImages}>
									insert
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImagePopUp;
