import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { _axios } from "../../api";
import { checkToken } from "../../App";
import { adminTokenCookieName } from "./../../utils/constants";

export interface SubMenu {
	name: string;
	url: string;
	submenu: SubMenu[];
	child?: boolean;
	type?: string;
}

export interface CreatedMenu {
	_id: string;
	name: string;
	slug: string;
	status: string;
	menu: SubMenu[];
}

export interface Menu {
	name: string;
	slug: string;
	status: string;
	menu: SubMenu[];
}

export interface MenusSliceState {
	menuDetail: CreatedMenu | {};
	menus: CreatedMenu[];
	loading: boolean;
	error: any;
}

const initialState: MenusSliceState = {
	menuDetail: {},
	menus: [],
	loading: false,
	error: null,
};

export const fetchMenus = createAsyncThunk("getMenus", async () => {
	checkToken();
	const res = await _axios.get(process.env.REACT_APP_BUILDER_URL + "menu", {
		headers: {
			Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
		},
	});
	return res.data;
});

export const findMenu = createAsyncThunk("findMenu", async (id: string) => {
	checkToken();
	const res = await _axios.get(
		process.env.REACT_APP_BUILDER_URL + "menu/" + id,
		{
			headers: {
				Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
			},
		}
	);
	return res.data;
});

export const asyncAddMenu = createAsyncThunk(
	"createMenu",
	async (menu: Menu) => {
		checkToken();
		const menuData = {
			name: menu.name,
			slug: createSlug(menu.name),
			status: menu.status,
			menu: menu.menu,
		};
		const res = await _axios.post(
			process.env.REACT_APP_BUILDER_URL + "menu",
			menuData,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			}
		);
		return res.data as CreatedMenu;
	}
);

export const asyncRemoveMenu = createAsyncThunk(
	"removeMenu",
	async (id: string) => {
		checkToken();
		const res = await _axios.delete(
			process.env.REACT_APP_BUILDER_URL + "menu/" + id
		);
		res.data._id = id;
		return res.data;
	}
);

export function createSlug(name: string) {
	return name?.toLowerCase()?.replace(/ /g, "_") || "";
}

export const menusSlice = createSlice({
	name: "menus",
	initialState,
	extraReducers: (builder) => {
		builder
			.addCase(fetchMenus.pending, (state) => {
				state.loading = true;
				state.menus = [];
				state.error = null;
			})
			.addCase(fetchMenus.fulfilled, (state, action) => {
				state.loading = false;
				state.menus = action.payload;
				state.error = null;
			})
			.addCase(fetchMenus.rejected, (state, action) => {
				state.loading = false;
				state.menus = [];
				state.error = action.error;
			})
			.addCase(asyncAddMenu.fulfilled, (state, action) => {
				state.menus.push(action.payload);
			})
			.addCase(asyncRemoveMenu.fulfilled, (state, action) => {
				state.menus = state.menus.filter(
					(menu) => menu._id !== action.payload._id
				);
			})
			.addCase(findMenu.pending, (state) => {
				state.menuDetail = {};
			})
			.addCase(findMenu.fulfilled, (state, action) => {
				state.menuDetail = action.payload;
			});
	},
	reducers: {},
});

export default menusSlice.reducer;
