import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { Logical, PageCategory, Widget } from "../../../widgetList/widgetList";
import { ImageContainerEditButton } from "../../components";
import CloseIcon from "../../icons/CloseIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import PlusIcon from "../../icons/PlusIcon";
import ImagePopUpV2 from "../../ImagePopUpV2";
import SettingFormItems, { SettingItem } from "../../SettingFormItems";
import { LinkData } from "../LinkForm";
import TopPanel, { Panel } from "../TopPanel";

interface ProductItemsType {
	custom?: number[];
	logical?: Logical;
}

interface TabType {
	name: string;
	logo?: string;
}

type SettingsNames =
	| "align"
	| "bgColor"
	| "bgImage"
	| "bgSize"
	| "bgPosition"
	| "bgRepeat"
	| "bgAttachment";

const settingItems: SettingItem<SettingsNames>[] = [
	{
		key: "align",
		name: "Alignment",
		type: "select",
		options: [
			{
				name: "Left",
				value: "left",
			},
			{
				name: "Center",
				value: "center",
			},
			{
				name: "Right",
				value: "right",
			},
		],
	},
	{
		key: "bgColor",
		name: "Background Color",
		type: "color",
	},
	{
		key: "bgImage",
		name: "Background Image",
		type: "image",
	},
	{
		key: "bgSize",
		name: "Background Size",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Auto",
				value: "auto",
			},
			{
				name: "Cover",
				value: "cover",
			},
			{
				name: "Contain",
				value: "contain",
			},
			{
				name: "100%",
				value: "100%",
			},
		],
	},
	{
		key: "bgPosition",
		name: "Background Position",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Center",
				value: "center",
			},
			{
				name: "Top",
				value: "top",
			},
			{
				name: "Bottom",
				value: "bottom",
			},
			{
				name: "Left",
				value: "left",
			},
			{
				name: "Right",
				value: "right",
			},
		],
	},
	{
		key: "bgRepeat",
		name: "Background Repeat",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "No Repeat",
				value: "no-repeat",
			},
			{
				name: "Repeat",
				value: "repeat",
			},
			{
				name: "Repeat X",
				value: "repeat-x",
			},
			{
				name: "Repeat Y",
				value: "repeat-y",
			},
		],
	},
	{
		key: "bgAttachment",
		name: "Background Attatchment",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Scroll",
				value: "scroll",
			},
			{
				name: "Fixed",
				value: "fixed",
			},
		],
	},
];

interface Props {
	choosenItem: any; // type: Widget
	onUpdate: (w: Widget) => void;
}

const FormForServices: FC<Props> = ({ choosenItem, onUpdate }) => {
	const dispatch = useDispatch();

	const [active, setActive] = useState<boolean>(choosenItem.active);

	const [title, setTitle] = useState(choosenItem?.data?.title || "");

	const [serviceTabs, setServiceTabs] = useState<TabType[]>(
		choosenItem?.data?.tabs || ([] as TabType[])
	);

	const [popupElement, setPopupElement] = useState<JSX.Element | null>(null);

	const [selectedCampaign, setSelectedCampaign] =
		useState<PageCategory | null>(null);

	const [panel, setPanel] = useState<Panel>("main");

	const [settings, setSettings] = useState<any>(
		choosenItem?.data?.settings as any
	);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const widgetObj: any = {
			name: choosenItem.name,
			active: active,
			data: {
				tabs: serviceTabs,
				title: title,
				settings: {
					...settings,
				},
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};

		onUpdate(widgetObj);
	};

	const ServiceTab = ({
		index,
		tab = {} as TabType,
		onSubmit,
	}: {
		index: number;
		tab?: TabType;
		onSubmit: (tab: TabType) => void;
	}) => {
		const [name, setName] = useState<string>(tab.name);
		const [logo, setLogo] = useState<string>(tab.logo || "");

		const handleSubmit = (e: any) => {
			e.preventDefault();

			onSubmit({
				name,
				logo,
			});
		};

		const handleImageSelector = (index?: number) => {
			dispatch(
				setGlobalPopupElement(
					<ImagePopUpV2
						single={true}
						onUpdate={(images) => {
							const arrayImages = images.map((item: any) => {
								return {
									image: item.path,
									title: item.title,
									link: {} as LinkData,
								};
							});

							// setImages(arrayImages)
							const item = arrayImages[0];

							setLogo(item.image);

							dispatch(setGlobalPopupElement(null));
						}}
						onClose={() => {
							dispatch(setGlobalPopupElement(null));
						}}
					/>
				)
			);
		};

		return (
			<div
				key={index}
				className="py-4 w-full min-h-[100%] absolute left-0 top-0 z-50 bg-white !block !px-0">
				{/* close button */}
				<button
					type="button"
					className="absolute right-0 top-0 z-50 cursor-pointer p-1 rounded-full border border-red-400 bg-red-400"
					onClick={() => {
						setPopupElement(null);
					}}>
					<CloseIcon />
				</button>

				<div className="flex items-center justify-between gap-4 pb-2">
					<h3 className="underline pb-1">Tab {index + 1}</h3>
				</div>
				<div className="flex items-center gap-4">
					<div className="capitalize tracking-wide">Name:</div>
					<input
						value={name}
						onChange={(e) => {
							const title = e.target.value;
							setName(title);
						}}
						type="text"
						placeholder="type here...."
					/>
				</div>

				<div className="flex items-center justify-between py-2 gap-4 w-[120px] h-[60px]">
					<div className="capitalize tracking-wide">Logo:</div>
					<ImageContainerEditButton
						index={index}
						imageUrl={
							logo ? process.env.REACT_APP_IMAGE_URL + logo : ""
						}
						handleImageSelector={handleImageSelector}
					/>
				</div>

				<div className="my-2 w-full"></div>

				{/* submit btn */}
				<div className="mt-4">
					<button
						onClick={handleSubmit}
						className="w-full py-3 px-2 bg-yellow-400">
						Submit
					</button>
				</div>
			</div>
		);
	};

	return (
		<form
			onSubmit={handleSubmit}
			className="mainSliderForm__container relative">
			<h1 className="title capitalize">{choosenItem.name}</h1>

			<TopPanel panel={panel} onChange={(name) => setPanel(name)} />
			{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

			{popupElement === null && (
				<>
					{panel === "main" ? (
						<div className="py-4 [&>*]:mb-4">
							<div>
								{serviceTabs.map((tab, index) => {
									return (
										<div
											key={index}
											className="p-2 py-4 rounded-md shadow-lg w-full flex justify-between items-start gap-2">
											<div>
												<p>Name: {tab.name}</p>
												{tab.logo && (
													<div className="flex items-center gap-4">
														<div className="capitalize tracking-wide">
															Logo:
														</div>
														<img
															className="w-[60px] h-[40px] object-contain"
															src={
																process.env
																	.REACT_APP_IMAGE_URL +
																tab.logo
															}
															alt=""
														/>
													</div>
												)}
											</div>
											<div className="flex flex-col gap-2">
												<button
													className="text-gray-500 px-2 py-1 rounded-md border border-gray-400 bg-gray-100 hover:bg-white"
													onClick={(e) => {
														e.preventDefault();

														setPopupElement(
															<ServiceTab
																index={index}
																tab={tab}
																onSubmit={(
																	tab
																) => {
																	const newTabs =
																		[
																			...serviceTabs,
																		];
																	newTabs[
																		index
																	] = tab;

																	setServiceTabs(
																		newTabs
																	);

																	setPopupElement(
																		null
																	);
																}}
															/>
														);
													}}>
													Edit
												</button>

												<button
													className="text-red-500 px-2 py-1 rounded-md border border-red-400 bg-red-100 hover:bg-white"
													onClick={(e) => {
														e.preventDefault();

														const newTabs = [
															...serviceTabs,
														];
														newTabs.splice(
															index,
															1
														);

														setServiceTabs(newTabs);
													}}>
													<DeleteIcon />
												</button>
											</div>
										</div>
									);
								})}

								{/* new add button */}
								<button
									className="text-gray-500 mt-4 px-3 py-2 rounded-md shadow-lg bg-gray-100 hover:bg-white w-full flex gap-4"
									onClick={(e) => {
										e.preventDefault();

										setPopupElement(
											<ServiceTab
												index={serviceTabs.length}
												onSubmit={(tab) => {
													setServiceTabs([
														...serviceTabs,
														tab,
													]);

													setPopupElement(null);
												}}
											/>
										);
									}}>
									<PlusIcon /> Add{" "}
									{serviceTabs.length > 0
										? "New"
										: "Service Tab"}
								</button>
							</div>
						</div>
					) : (
						<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
							<SettingFormItems
								settings={settings}
								widget={choosenItem}
								settingItems={settingItems}
								onChange={(key, value) => {
									setSettings((prev: any) => ({
										...prev,
										[key]: value,
									}));
								}}
							/>
						</div>
					)}

					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</>
			)}

			{popupElement}
		</form>
	);
};

export default FormForServices;
