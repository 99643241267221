import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { shuffleArray } from "../utils";
import { getProductByIds, getProductsByLogic } from "../utils/requests";
import { Widget } from "../widgetList/widgetList";

const useLogicalProducts = ({ widget }: { widget?: Widget }) => {
	const shop = useSelector((state: RootState) => state.auth.shop);

	const [products, setProducts] = useState<any[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<any>();

	useEffect(() => {
		if (shop?.apiKey) {
			setLoading(true);
			setError(undefined);

			if (!!widget?.data?.setting?.custom?.length) {
				getProductByIds(
					widget?.data?.setting?.custom?.map((p: any) => p.id) || [],
					shop?.apiKey
				)
					.then((res) => {
						setProducts(res.data);
						setLoading(false);
					})
					.catch((err) => {
						setError(err);
						setLoading(false);
					});
			} else if (!!widget?.data?.setting?.logical) {
				getProductsByLogic(widget?.data?.setting?.logical, shop?.apiKey)
					.then((res) => {
						const logical = widget?.data?.setting?.logical;
						if (
							!logical?.isFeatured &&
							(logical?.categoryOrder === "random" ||
								logical?.brandOrder === "random")
						) {
							shuffleArray(res);
						}
						setProducts(res);
						setLoading(false);
					})
					.catch((err) => {
						setError(err);
						setLoading(false);
					});
			} else {
				setLoading(false);
			}
		}
	}, [widget, shop]);

	return {
		products,
		loading,
		error,
	};
};

// ** exports
export default useLogicalProducts;
