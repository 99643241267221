import React, { FC, useEffect, useState } from "react";
import { Widget } from "../../../widgetList/widgetList";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../../SettingFormItems";
import LinkForm, { LinkData } from "../LinkForm";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

type Panel = "main" | "settings";
const panels: {
	name: Panel;
	title: string;
}[] = [
	{
		name: "main",
		title: "Main",
	},
	{
		name: "settings",
		title: "Settings",
	},
];

type SettingsNames =
	| "isSlider"
	| "showButton"
	| "width"
	| "height"
	| "objectFit"
	| "col"
	| "autoplay"
	| "delay"
	| "gap"
	| "bgColor"
	| "bgImage"
	| "bgSize"
	| "bgPosition"
	| "bgRepeat"
	| "bgAttachment"
	| "fontColor"
	| "customCSS";

const settingItems: SettingItem<SettingsNames>[] = [
	{
		key: "bgColor",
		name: "Background Color",
		type: "color",
	},
	{
		key: "bgImage",
		name: "Background Image",
		type: "image",
	},
	{
		key: "fontColor",
		name: "Font Color",
		type: "color",
	},
	{
		key: "bgSize",
		name: "Background Size",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Auto",
				value: "auto",
			},
			{
				name: "Cover",
				value: "cover",
			},
			{
				name: "Contain",
				value: "contain",
			},
			{
				name: "100%",
				value: "100%",
			},
		],
	},
	{
		key: "bgPosition",
		name: "Background Position",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Center",
				value: "center",
			},
			{
				name: "Top",
				value: "top",
			},
			{
				name: "Bottom",
				value: "bottom",
			},
			{
				name: "Left",
				value: "left",
			},
			{
				name: "Right",
				value: "right",
			},
		],
	},
	{
		key: "bgRepeat",
		name: "Background Repeat",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "No Repeat",
				value: "no-repeat",
			},
			{
				name: "Repeat",
				value: "repeat",
			},
			{
				name: "Repeat X",
				value: "repeat-x",
			},
			{
				name: "Repeat Y",
				value: "repeat-y",
			},
		],
	},
	{
		key: "bgAttachment",
		name: "Background Attatchment",
		type: "select",
		options: [
			{
				name: "None",
				value: "",
			},
			{
				name: "Scroll",
				value: "scroll",
			},
			{
				name: "Fixed",
				value: "fixed",
			},
		],
	},
	{
		name: "Custom CSS",
		key: "customCSS",
		type: "customCSS",
	},
];

const FormForButton: FC<Props> = ({ choosenItem, onUpdate }) => {
	const [active, setActive] = useState(false);
	const [isBuyNow, setIsBuyNow] = useState(
		choosenItem.data?.settings?.isBuyNow ?? true
	);
	const [title, setTitle] = useState("");
	const [link, setLink] = useState<LinkData>({} as LinkData);

	const [panel, setPanel] = useState<Panel>("main");
	const prevSettings = (choosenItem as any)?.data?.settings as any;
	const [settings, setSettings] =
		useState<SettingItems<SettingsNames>>(prevSettings);

	const [campaign, setCampaign] = useState<any>();

	useEffect(() => {
		setTitle(choosenItem.title || "");
		setLink(choosenItem.link || ({} as LinkData));
	}, [choosenItem]);

	const handleTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const titleObj = {
			name: choosenItem.name,
			active: active,
			title: title,
			link: link,
			wType: choosenItem.wType,
			id: choosenItem.id,
			data: {
				settings: {
					isBuyNow: isBuyNow,
					...settings,
				},
			},
		};
		onUpdate(titleObj);
	};

	const handleLinkData = (data: LinkData, image?: any, campaign?: any) => {
		setLink(data);
		if (campaign) {
			setCampaign(campaign);
		}
	};
	return (
		<form onSubmit={handleSubmit} className="mainSliderForm__container">
			<h1 className="title capitalize">{choosenItem.name}</h1>
			{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}

			{/* panels switch */}
			<div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0">
				{panels.map((p) => (
					<button
						key={p.name}
						type="button"
						className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
							panel === p.name
								? "border-green-500 text-black"
								: "text-black/50 hover:text-black/70"
						}`}
						onClick={() => setPanel(p.name)}>
						{p.title}
					</button>
				))}
			</div>

			{panel === "main" ? (
				<div>
					<div>
						<div className="capitalize tracking-wide">title :</div>
						<input
							value={title}
							onChange={handleTitle}
							type="text"
							placeholder="type here...."
						/>
					</div>
					<div className="checkbox mt-3">
						<div className="capitalize tracking-wide">
							Buy Now Button :
						</div>
						<input
							type="checkbox"
							checked={isBuyNow}
							onChange={() => setIsBuyNow(!isBuyNow)}
						/>
					</div>
					{!isBuyNow && (
						<div className="mt-3">
							<LinkForm
								currnetLinkData={link}
								onUpdate={handleLinkData}
							/>
						</div>
					)}
				</div>
			) : (
				<SettingFormItems
					settings={settings}
					widget={choosenItem}
					settingItems={[...settingItems]}
					onChange={(key, value) => {
						setSettings((prev) => ({
							...prev,
							[key]: value,
						}));
					}}
				/>
			)}
			<div>
				<button className="mainSliderForm__btn">save</button>
			</div>
		</form>
	);
};

export default FormForButton;
