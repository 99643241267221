import { MenuWidgetType } from "../types/widget";

export const adminUserProfileUrl = process.env.REACT_APP_USER_PROFILE_URL || "";

export const adminTokenCookieName =
	process.env.REACT_APP_TOKEN_COOKIE_NAME || "curios-admin-token";

export const placeholderImaage = "/img/image-placeholder.jpg";

export const cookieSecret = {
	key: "secret-log",
	value: "bbt@2023",
};

export const logos = {
	faviconBlack: "/logos/fabicon.ico",
	faviconWhite: "/logos/fabicon.ico",
	fullLogoBlack: "/logos/logo.png",
	iconOng: "/logos/logo.png",
	iconWhite: "/logos/logo.png",
	logoBanglaBlack: "/logos/logo.png",
	logoWhite: "/logos/logo.png",
};

export const envs = {
	domainUrl: process.env.REACT_APP_COOKIE_URL,
	mainDomainUrl: process.env.REACT_APP_MAIN_DOMAIN_URL,
	baseApi: process.env.REACT_APP_BASE_URL,
	apiVersion: process.env.NEXT_PUBLIC_API_VERSION,
	homeUrl: process.env.REACT_APP_LOGIN_REDIRECT,
	imageBasePath: process.env.REACT_APP_IMAGE_URL,
	frontendApiUrl:
		process.env.REACT_APP_FRONTEND_API_URL ||
		process.env.REACT_APP_BASE_URL,
	adminUrl: process.env.REACT_APP_LOGIN_REDIRECT,
};

export const menuWidgetNames: MenuWidgetType[] = [
	"footer-select-e1b2",
	"header-select-e1b2",
	"headers-e1b2",
	"theme-select-e1b2",
];
