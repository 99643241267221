import React, { useEffect, useState } from "react";
import DeleteIcon from "../../components/icons/DeleteIcon";
import EditIcon from "../../components/icons/EditIcon";

import "./menuPage.css";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import type { AppDispatch, RootState } from "../../app/store";
import {
	asyncAddMenu,
	asyncRemoveMenu,
	CreatedMenu,
	fetchMenus,
	Menu,
} from "../../features/addMenu";

import swal from "sweetalert";

const MenuPage: React.FC = () => {
	const getMenus = useSelector((state: RootState) => state.menus.menus);
	const permissions = useSelector(
		(state: RootState) => state.authPermission.permissions
	);

	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(fetchMenus());
	}, []);

	const [name, setName] = useState("");
	const [slug, setSlug] = useState("");
	const [menu, setMenu] = useState([]);

	const addMenu: Menu = {
		name,
		slug,
		status: "draft",
		menu,
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const value = e.target.value;
		setName(value);
	};

	const handleSumbit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		dispatch(asyncAddMenu(addMenu));
		setName("");
	};

	const handleDeletePage = (id: string) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this page file!",
			icon: "warning",
			buttons: [true, true],
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				swal("Your page file has been deleted!", {
					icon: "success",
				});

				dispatch(asyncRemoveMenu(id));
			} else {
				swal("Your page file is safe!");
			}
		});
	};

	return (
		<div className="menupage__container">
			{permissions.includes("create_builder_menu") && (
				<div className="menupage__row--one">
					<form className="newmenu_form" onSubmit={handleSumbit}>
						<input
							value={name}
							type="text"
							placeholder="Menu Name..."
							onChange={handleOnChange}
						/>
						<button className="btn" type="submit">
							Add new
						</button>
					</form>
				</div>
			)}
			<div className="menupage__row--two">
				<ul>
					{getMenus.map((menu: CreatedMenu) => (
						<li key={menu._id}>
							{menu.name}
							<div className="btns__wrapper">
								{permissions.includes(
									"update_builder_menu"
								) && (
									<Link
										to={`/menus/${menu._id}`}
										className="edit__btn">
										<EditIcon />
									</Link>
								)}
								{permissions.includes(
									"delete_builder_menu"
								) && (
									<div
										className="delete__btn"
										onClick={() => {
											handleDeletePage(menu._id);
										}}>
										<DeleteIcon />
									</div>
								)}
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default MenuPage;
