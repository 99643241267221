import useLogicalProducts from "../../hooks/useLogicalProducts";
import { RenderWidgetProps } from "../../widgetList/widgetList";
import ProductSlider from "../Widgets/ProductSlider";
import ProductCard from "./ProductCard";

const ProductRowPreview = ({ widget }: RenderWidgetProps) => {
	const { products, loading, error } = useLogicalProducts({
		widget,
	});

	if (loading || products.length === 0) return <ProductSlider />;

	return (
		<div className="grid grid-cols-2 md:grid-cols-5 gap-4">
			{products.slice(0, 5).map((product: any) => {
				return <ProductCard productItem={product} />;
			})}
		</div>
	);
};

export default ProductRowPreview;
