import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PermissionType } from "../types/permission";

export interface StateType {
	shop: any;
	permissions: PermissionType[];
}

const initialState: StateType = {
	shop: null,
	permissions: [],
};

export const authPermissionsSlice = createSlice({
	name: "authPermissions",
	initialState,
	reducers: {
		setAuthShopData: (state, action: PayloadAction<any>) => {
			state.shop = action.payload;
		},
		setPermissions: (state, action: PayloadAction<PermissionType[]>) => {
			state.permissions = action.payload;
		},
	},
});

export const { setAuthShopData, setPermissions } = authPermissionsSlice.actions;

export default authPermissionsSlice.reducer;
