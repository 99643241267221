import {
	RenderWidgetProps,
	Widget,
	widgetData,
} from "../../widgetList/widgetList";
import PlusIcon from "../icons/PlusIcon";
import { VideoBanner } from "../Widgets/Banner";

export const widgetDropClassName = "grid__widget__drop";

const GridWidgets = ({ widget, onWidgetDrop }: RenderWidgetProps) => {
	const widgets = widget?.data?.widgets || [];

	// if (!widgets.length) return <GridCard />;

	return (
		<div
			className="grid gap-4 grid-cols-1"
			style={{
				gridTemplateColumns: `repeat(${widgets.length}, 1fr) 0.5fr`,
			}}>
			{widgets.map((w: Widget, index: number) => {
				const Comp =
					w.Component || widgetData[w.name]?.Component || VideoBanner;

				const RenderComp =
					w.RenderWidget || widgetData[w.name]?.RenderWidget;

				return (
					<div key={index} className="mb-4">
						{RenderComp ? <RenderComp widget={w} /> : <Comp />}
					</div>
				);
			})}
			<div
				className={`input__field bg-white !h-full min-h-[200px] my-4 shadow-md ${widgetDropClassName}`}
				onDrop={onWidgetDrop}>
				<PlusIcon />
			</div>
		</div>
	);
};

export default GridWidgets;
