import clsx, { ClassValue } from "clsx";
import Cookies from "js-cookie";
import { CSSProperties } from "react";
import { toast } from "react-toastify";
import { twMerge } from "tailwind-merge";
import { MenuWidgetType } from "../types/widget";
import { adminTokenCookieName, envs } from "./constants";
import { createWidgetItem, getWidgetList } from "./requests";

export const getTokenCookie = (): string => {
	return Cookies.get(adminTokenCookieName) || "";
};

export const getImageUrl = (path: string): string => {
	return `${envs.imageBasePath}${path}`;
};

export const objectKeys = <T extends object>(obj: T): (keyof T)[] => {
	return Object.keys(obj) as (keyof T)[];
};

export const toastMe = (
	message: string | any,
	type: "success" | "error" | "warning" | "info" = "success",
	extraMsg = ""
): void => {
	console.log(message, type, extraMsg);

	if (typeof message === "string") {
		toast(message, {
			position: "bottom-right",
			autoClose: 2000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
			type: type,
		});
	} else if (
		typeof message === "object" &&
		objectKeys(message?.response?.data?.error || {}).length > 0
	) {
		const obj = message?.response?.data.error || {};
		const keys = objectKeys(obj);

		keys.forEach((key) => {
			obj[key].forEach((msg: string) => {
				toastMe(msg, "error");
			});
		});
	} else {
		toastMe(extraMsg || "Something went wrong", type);
	}
};

export const getFrontEndUrl = (slug: string): string => {
	return `https://${slug}.${envs.mainDomainUrl}`;
};

export const getSectionStyle = (settings?: any) => {
	// WidgetSettings
	const style: CSSProperties = {
		backgroundColor: settings?.bgColor || undefined,
		backgroundImage: settings?.bgImage
			? `url(${getImageUrl(settings?.bgImage)})`
			: undefined,
		backgroundSize: settings?.bgSize || undefined,
		backgroundPosition: settings?.bgPosition || undefined,
		backgroundRepeat: settings?.bgRepeat || undefined,
		backgroundAttachment: settings?.bgAttachment || undefined,
		color: settings?.fontColor || undefined,
	};

	return style;
};

export const convertUTC0toDhaka = (date: string | Date) => {
	const dateTime = new Date(date).getTime() + 6 * 60 * 60 * 1000;
	return new Date(date);
};

export function numberWithCommas(price: number) {
	return new Intl.NumberFormat("en-IN", { currency: "BDT" }).format(price);
}

export const toDubleDigit = (num: number) => (num < 10 ? "0" + num : "" + num);

export const objectKeysTS = <T extends object>(obj: T) =>
	Object.keys(obj) as (keyof T)[];

export const isBangladeshiPhoneNumber = (phone: string) => {
	const regex = /^(?:(?:\+|00)88|01)?\d{11}$/;
	return regex.test(phone);
};

export const textLetterLimit = (text: string, limit: number) => {
	if (text.length > limit) {
		return text.slice(0, limit) + "...";
	} else {
		return text;
	}
};

type Procedure = (...args: any[]) => void;

export function debounce<F extends Procedure>(
	func: F,
	delay: number
): (...args: Parameters<F>) => void {
	let timeout: ReturnType<typeof setTimeout>;

	return function (this: unknown, ...args: Parameters<F>) {
		const context = this as any;
		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(context, args), delay);
	};
}

export const getImageArray = (image?: string) => {
	if (!image) return [];
	return image.split(",");
};

export function convertNameChar(str: string) {
	return str
		.replace(/&lt;/g, "<")
		.replace(/&gt;/g, ">")
		.replace(/&quot;/g, '"')
		.replace(/&#039;/g, "'")
		.replace(/&amp;/g, "&");
}

export function shuffleArray(array: any[]) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
}

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const findMenuWidget = (widgets: any[], name: MenuWidgetType) => {
	return widgets.find((widget) => widget.name === name);
};

export const getThemeBannerUrl = (url: string) => {
	return url?.startsWith("http") ? url : getImageUrl(url) || "";
};

export const findWidgetByName = (name: string): Promise<any> => {
	return new Promise((resolve, reject) => {
		getWidgetList()
			.then((res) => {
				const widgets = res.data || [];
				const widget = widgets.find((w) => w.name === name);
				if (widget) {
					resolve(widget);
				} else {
					createWidgetItem({
						name: name,
						status: "published",
						active: true,
						code: name?.toLowerCase().replace(/ /g, "-"),
						settings: {},
						is_deletable: 0,
					}).then((res) => {
						resolve(res);
					});
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getWidgetClassName = (widget?: any) => {
	return (
		"w-" + widget?.name?.toLowerCase().replace(/ /g, "-") + "-" + widget?.id
	);
};

export const getImageMeta = (url: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(img);
		img.onerror = (err) => reject(err);
		img.src = url;
	});
};
