import { useEffect, useMemo, useState } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../app/store";
import { setAuthShopData } from "../../features/authSlice";
import { setLoading } from "../../features/popupSlice";
import { ThemeItem } from "../../types/theme";
import { findWidgetByName, getThemeBannerUrl } from "../../utils";
import {
	changeShopTheme,
	getShopTypes,
	getThemeList,
} from "../../utils/requests";

const ThemesPage = () => {
	const dispatch = useDispatch();
	const shop = useSelector((state: RootState) => state.auth.shop);

	const [themeSettingsW, setThemeSettingsW] = useState<any | null>(null);
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const [selectedShopType, setSelectedShopType] = useState<number>();
	const [themes, setThemes] = useState<ThemeItem[]>([]);
	const [shopTypes, setShopTypes] = useState<any[]>([]);

	useEffect(() => {
		getShopTypes().then((res) => {
			setShopTypes([
				{
					name: "Select Shop Type",
					slug: "",
					id: 0,
				},
				...res,
			]);
		});

		findWidgetByName("Theme Settings").then((res) => {
			setThemeSettingsW(res);
		});
	}, []);

	useEffect(() => {
		getThemeList({
			currentPage: 1,
			perPage: 100,
			status: "active",
			shopType: selectedShopType,
		}).then((res) => {
			setThemes(res.data);
		});
	}, [selectedShopType]);

	const handleSelectTheme = (theme: ThemeItem) => {
		dispatch(setLoading(true));
		changeShopTheme({
			themeId: theme.id,
		})
			.then((res) => {
				dispatch(setAuthShopData(res));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

	const handleSelectShopType = (e: any) => {
		const type = e.target.value;

		setSelectedShopType(+type || undefined);
	};

	const themesSorted = useMemo(() => {
		const index = themes.findIndex((theme) => theme.id === shop?.theme_id);
		if (index > -1) {
			const selectedTheme = themes[index];
			themes.splice(index, 1);
			themes.unshift(selectedTheme);
		}
		return themes;
	}, [themes, shop?.theme_id]);

	return (
		<div>
			<div className="flex gap-2 justify-between">
				<h1 className="text-3xl font-bold">Themes</h1>

				<div className="flex gap-4 items-center">
					<label className="mainSliderForm__label">Shop Type</label>
					<div className="mainSliderForm__input">
						<select
							className="p-2 border border-gray-300 rounded-md w-full"
							value={selectedShopType}
							onChange={handleSelectShopType}>
							{shopTypes?.map((item, index) => (
								<option key={index} value={item.id}>
									{item.name}
								</option>
							))}
						</select>
					</div>

					<Link
						to={`/widgets/${themeSettingsW?._id}`}
						className="bg-gray-200 p-2 rounded-full mr-2 group">
						<IoSettingsSharp
							size={28}
							className="group-hover:rotate-90 transition-transform"
						/>
					</Link>
				</div>
			</div>
			<div className="grid grid-cols-2 lg:grid-cols-3 gap-6 mt-4">
				{themesSorted.map((theme, index) => (
					<div
						key={index}
						className="bg-white rounded-md shadow-lg relative">
						<div
							className="absolute top-0 left-0 p-4 bg-white/70 hover:bg-white shadow-md transition-colors"
							style={{
								borderRadius: "0 0 100% 0",
								padding: "0.5rem 1rem 1rem 0.5rem",
							}}>
							{/* <Checkbox
								checked={selectedIndex === index}
								onChange={() => setSelectedIndex(index)}
							/> */}
						</div>

						<img
							src={getThemeBannerUrl(theme.banner)}
							alt={theme.name}
							style={{
								aspectRatio: 1280 / 1440,
							}}
							onClick={() => setSelectedIndex(index)}
							className="w-full object-cover object-top rounded-md cursor-pointer"
						/>
						<div className="flex justify-between">
							<div className="mt-4 p-4 pt-0">
								<h3 className="text-lg font-bold">
									{theme.name}
								</h3>
								<p className="text-sm text-gray-500">
									{theme.description}
								</p>
							</div>

							<div className="p-4">
								{shop.theme_id === theme.id ? (
									<button
										disabled
										className="bg-gray-200 p-2 rounded-md mr-2">
										Selected
									</button>
								) : (
									<button
										onClick={() => handleSelectTheme(theme)}
										className="btn px-2">
										Select
									</button>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ThemesPage;
