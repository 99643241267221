import { placeholderImaage } from "../utils/constants";
import EditIcon from "./icons/EditIcon";

export const ImageContainerEditButton = ({
	handleImageSelector,
	index,
	imageUrl,
	title,
}: {
	handleImageSelector: (index: number) => void;
	index: number;
	imageUrl: string;
	title?: string;
}) => (
	<div className="relative">
		<img
			className="rounded-md mb-1 min-h-[60px] max-h-[200px] object-contain mx-auto"
			src={imageUrl || placeholderImaage}
			alt={title || "img"}
		/>
		<div
			className="absolute flex justify-center items-center top-0 right-0 w-full h-full bg-black/50 transition-opacity duration-500 opacity-0 hover:opacity-100 cursor-pointer"
			onClick={(e) => {
				e.preventDefault();
				handleImageSelector(index);
			}}>
			<button className="p-2 [&>*]:stroke-white">
				<EditIcon />
			</button>
		</div>
	</div>
);
