import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { useDetectClickOutside } from "react-detect-click-outside";
import { IoSettingsSharp } from "react-icons/io5";
import swal from "sweetalert";
import { showToast } from ".";
import CloseIcon from "../../components/icons/CloseIcon";
import { SettingItem } from "../../components/SettingFormItems";
import CustomWidgetForm, {
	FormItem,
} from "../../components/widgetFrom/CustomWidgetForm";
import { Page } from "../../features/addPages/addPages";
import { setLoading } from "../../features/popupSlice";
import { getPageDetails, updateAllPages } from "../../modules/mediaManager";
import { getProductList } from "../../utils/requests";
import { PageCategory } from "../../widgetList/widgetList";

type FormKeys =
	| "status"
	| "title"
	| "subtitle"
	| "description"
	| "video"
	| "videos"
	| "images"
	| "image"
	| "list-texts";

type PageSettingKeys =
	| "buy-now-text"
	| "main-logo"
	| "title"
	| "subtitle"
	| "footerText";

type SettingKeys = "padding";

const sections: {
	title: string;
	image: string;
	formItems: FormItem<FormKeys>[];
	settings?: SettingItem<SettingKeys>[];
}[] = [
	{
		title: "Hero Section",
		image: "/landing-pages/methix/hero.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "texteditor",
			},
			{
				key: "description",
				name: "Description",
				type: "texteditor",
			},
			{
				key: "video",
				name: "Video",
				type: "text",
				placeholder: "https://www.youtube.com/watch?v=video_id",
			},
		],
		settings: [
			{
				key: "padding",
				name: "Padding",
				type: "text",
			},
		],
	},
	{
		title: "Services Section",
		image: "/landing-pages/methix/service.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "images",
				name: "Images",
				type: "images",
			},
		],
	},
	{
		title: "Features Section",
		image: "/landing-pages/methix/features.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "texteditor",
			},
			{
				key: "images",
				name: "Images",
				type: "images",
			},
		],
	},
	{
		title: "About Section",
		image: "/landing-pages/methix/about.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "texteditor",
			},
			{
				key: "image",
				name: "Image",
				type: "image",
			},
			{
				key: "list-texts",
				name: "List Texts",
				type: "texteditor-list",
			},
		],
	},
	{
		title: "Video Section",
		image: "/landing-pages/methix/video.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "texteditor",
			},
			{
				key: "video",
				name: "Video",
				type: "text",
				placeholder: "https://www.youtube.com/watch?v=video_id",
			},
		],
	},
	{
		title: "Info Section",
		image: "/landing-pages/methix/info.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "image",
				name: "Image",
				type: "image",
			},
			{
				key: "title",
				name: "Title",
				type: "texteditor",
			},
			{
				key: "subtitle",
				name: "Subtitle",
				type: "texteditor",
			},
			{
				key: "list-texts",
				name: "List Texts",
				type: "texteditor-list",
			},
		],
	},
	{
		title: "Ingredients Section",
		image: "/landing-pages/methix/ingredients.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "texteditor",
			},
			{
				key: "description",
				name: "Description",
				type: "texteditor",
			},
			{
				key: "images",
				name: "Contents",
				type: "images",
			},
		],
	},
	{
		title: "Making Section",
		image: "/landing-pages/methix/makings.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "text",
			},
			{
				key: "image",
				name: "Image",
				type: "image",
			},
		],
	},
	{
		title: "Posts Section",
		image: "/landing-pages/methix/posts.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "text",
			},
			{
				key: "images",
				name: "Images",
				type: "images",
			},
		],
	},
	{
		title: "Videos Section",
		image: "/landing-pages/methix/videos.png",
		formItems: [
			{
				key: "status",
				name: "Status",
				type: "select",
				options: [
					{ value: "active", name: "Active" },
					{ value: "inactive", name: "Inactive" },
				],
			},
			{
				key: "title",
				name: "Title",
				type: "texteditor",
			},
			{
				key: "video",
				name: "Video List",
				type: "text-list",
				placeholder: "https://www.youtube.com/watch?v=video_id",
			},
		],
	},
];

const settingForms: FormItem<PageSettingKeys>[] = [
	{
		key: "main-logo",
		name: "Main Logo",
		type: "image",
	},
	{
		key: "buy-now-text",
		name: "Buy Now Text",
		type: "text",
	},
	{
		key: "title",
		name: "Title",
		type: "text",
	},
	{
		key: "subtitle",
		name: "Subtitle",
		type: "text",
	},
	{
		key: "footerText",
		name: "Footer Text",
		type: "text",
	},
];

const widgetName = "landing-page";

interface FormStateType {
	[key: string]: any;
}

const CustomLandingPage = () => {
	const dispatch = useDispatch();
	const { pageTitle } = useParams();

	const [page, setPage] = useState<Page | null>(null);
	const [openSettings, setOpenSettings] = useState<boolean>(false);

	const [selectedSection, setSelectedSection] = useState<number | null>(null);
	const [formState, setFormState] = useState<FormStateType[]>(
		sections.map(() => ({}))
	);
	const [pageSettings, setPageSettings] = useState<FormStateType>({});

	const [mainPageProducts, setMainProducts] = useState<PageCategory[]>([]);
	const [pageProduct, setPageProducts] = useState<any[]>([]);
	const [showPageProducts, setShowPageProducts] = useState(false);
	const [pageProductText, setPageProductText] = useState("");

	useEffect(() => {
		dispatch(setLoading(true));

		getPageDetails(pageTitle)
			.then((res) => {
				setPage(res.data);
				setFormState(
					res.data.widgets?.[0]?.data || sections.map(() => ({}))
				);
				setMainProducts(res.data.products || []);
				setPageSettings(res.data.settings || {});
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	}, [pageTitle, dispatch]);

	const patchPageData = (status: "draft" | "published") => {
		const widgets = [
			{
				name: widgetName,
				title: widgetName,
				active: true,
				data: formState,
			},
		];

		const handleUpdatePage = (screenMode?: string) => {
			const newData: any = {
				...page,
				status: status,
				widgets: widgets,
				settings: pageSettings,
				...(screenMode && { screenMode: screenMode }),
			};

			updateAllPages(
				newData,
				widgets,
				"widgets",
				[],
				[],
				[],
				mainPageProducts
			)
				.then((res) => {
					// dispatch(fetchAllPages())
					setPage(res.data);
					if (status === "published") {
						swal("Good job!", "Page published", "success");
					} else {
						showToast("Added and saved as draft");
					}
				})
				.catch(() => {
					swal("Something went wrong!", "", "error");
				});
		};

		if (page?.is_front_page && status === "published") {
			handleUpdatePage("all");
		} else {
			handleUpdatePage();
		}
	};

	const getPageProduct = (text?: string) => {
		getProductList({
			search: text,
			status: "active",
		}).then((data) => {
			setPageProducts(data);
		});
	};

	const handleDeletePageProduct = (index: number) => {
		const filteredPageProduct = mainPageProducts.filter(
			(_, i) => i !== index
		);

		setMainProducts(filteredPageProduct);
	};
	const closePageProductResult = () => {
		setShowPageProducts(false);
	};
	const refPageProductResult = useDetectClickOutside({
		onTriggered: closePageProductResult,
	});

	const handlePageProductSearch = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setPageProductText(e.target.value);
		getPageProduct(e.target.value);
		if (pageProduct?.length > 0) {
			setShowPageProducts((prev) => (prev = !prev));
		}
	};

	const handleChoosePageProduct = (item: any) => {
		const _id = item.id;
		const _name = item.name;
		const _slug = item.slug;

		const newObj: any = {
			id: _id,
			name: _name,
			slug: _slug,
		};

		const _mainPageProducts = [...mainPageProducts];
		_mainPageProducts.push(newObj);
		setMainProducts(_mainPageProducts);
	};

	return (
		<div>
			{sections.map((section, index) => (
				<div
					key={index}
					className={
						"p-2 mb-2 border border-gray-300 cursor-pointer transition-colors hover:border-gray-500 flex justify-center items-center " +
						(selectedSection === index ? "border-green-500" : "")
					}
					onClick={() => setSelectedSection(index)}>
					<img src={section.image} alt={section.title} />
				</div>
			))}

			{/* form items */}
			{selectedSection !== null ? (
				<div
					className="fixed right-2 top-[100px] bg-white w-[50vw] max-w-[400px] h-[85vh] overflow-y-auto rounded-md shadow-md pb-0"
					style={{
						animation: "slideIn 0.3s",
					}}>
					{/* close */}
					<button
						onClick={() => setSelectedSection(null)}
						className="sticky top-0 right-0 p-2 bg-gray-200 rounded-[0_10%_60%_10%] z-50 shadow-sm">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>

					<div className="p-4">
						<CustomWidgetForm
							key={selectedSection}
							formItems={sections[selectedSection].formItems}
							data={formState[selectedSection]}
							onChange={(key, value) => {
								console.log("key, value :>> ", key, value);

								setFormState((prev) => {
									const copyPrev = [...prev];
									if (!copyPrev[selectedSection]) {
										copyPrev[selectedSection] = {};
									}

									copyPrev[selectedSection] = {
										...copyPrev[selectedSection],
										[key]: value,
									};

									return copyPrev;
								});
							}}
							title={sections[selectedSection].title}
						/>
					</div>

					<hr />
					{/* save button */}
					<div className="sticky left-0 bottom-0 w-full text-center p-4 bg-white shadow-md">
						<button
							className="w-full px-4 py-2 border border-green-500 hover:bg-green-500 text-green-600 hover:text-white rounded-md transition-colors"
							onClick={() => {
								patchPageData("draft");
							}}>
							Save Widget
						</button>
					</div>
				</div>
			) : openSettings ? (
				<div
					className="fixed right-2 top-[100px] bg-white w-[50vw] max-w-[400px] h-[85vh] overflow-y-auto rounded-md shadow-md pb-0"
					style={{
						animation: "slideIn 0.3s",
					}}>
					{/* close */}
					<button
						onClick={() => setOpenSettings(false)}
						className="sticky top-0 right-0 p-2 bg-gray-200 rounded-[0_10%_60%_10%] z-50 shadow-sm">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor">
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>

					<div className="p-4">
						<div className=" overflow-y-auto slideInAnimeRight pagedetails__col--three">
							{page?.type === "landing-page" && (
								<div className="bg-white drop-shadow-lg rounded-md p-2 mb-6">
									<div className="text-sm tracking-wide mb-1">
										Page Products :
									</div>
									{mainPageProducts?.map(
										(item: PageCategory, index) => {
											return (
												<div
													key={index}
													className="relative flex items-center justify-between p-3 bg-white drop-shadow-lg mb-3 rounded-md text-sm font-light">
													<div className="truncate pr-6 w-[80%]">
														{item.name}({item.slug})
													</div>
													<div className="w-[20%]">
														ID: {item.id}
													</div>
													<div
														className="absolute z-10 -top-2 -right-2 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer"
														onClick={() =>
															handleDeletePageProduct(
																index
															)
														}>
														<CloseIcon />
													</div>
												</div>
											);
										}
									)}
									<div className="relative">
										<input
											type="text"
											placeholder="Search For Products"
											onChange={handlePageProductSearch}
										/>
										{showPageProducts && (
											<div
												ref={refPageProductResult}
												className="absolute top-10 left-0 right-0 max-h-40 min-h-fit overflow-y-auto bg-white my-2 px-2 divide-y z-10">
												{pageProduct?.map(
													(item, index) => {
														return (
															<div
																onClick={() =>
																	handleChoosePageProduct(
																		item
																	)
																}
																key={index}
																className="p-2 text-sm tracking-wide truncate cursor-pointer">
																{item.name}
															</div>
														);
													}
												)}
											</div>
										)}
										<div
											className="w-full bg-green-500 text-white text-center text-sm tracking-wider py-2 rounded-md mt-2 cursor-pointer"
											onClick={() =>
												patchPageData("draft")
											}>
											Add Product
										</div>
									</div>
								</div>
							)}
						</div>
						<CustomWidgetForm
							formItems={settingForms}
							data={pageSettings}
							onChange={(key, value) => {
								setPageSettings((prev) => {
									const copyPrev = { ...prev };
									copyPrev[key] = value;
									return copyPrev;
								});
							}}
							title="Settings"
						/>
					</div>

					<hr />
					{/* save button */}
					<div className="sticky left-0 bottom-0 w-full text-center p-4 bg-white shadow-md">
						<button
							className="w-full px-4 py-2 border border-green-500 hover:bg-green-500 text-green-600 hover:text-white rounded-md transition-colors"
							onClick={() => {
								patchPageData("draft");
							}}>
							Save Widget
						</button>
					</div>
				</div>
			) : null}

			{/* publish button */}
			<div className="sticky w-auto rounded-md left-2 bottom-2 p-2 bg-white shadow-md inline-flex items-center gap-2">
				<button
					onClick={() => {
						patchPageData("published");
					}}
					disabled={page?.status === "published"}
					className="px-4 py-2 bg-green-500 disabled:bg-gray-400 text-white rounded-md">
					Publish
				</button>

				<button
					onClick={() => setOpenSettings(true)}
					className="bg-gray-100 rounded-full p-1 transition-transform hover:rotate-90">
					<IoSettingsSharp size={28} />
				</button>
			</div>
		</div>
	);
};

export default CustomLandingPage;
