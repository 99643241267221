import { FaCopy, FaCss3Alt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../features/popupSlice";
import PopupWrapper from "./PopupWrapper";

import { useState } from "react";
import StyleEditor from "react-style-editor";
import { showToast } from "../pages/HomePage";
import { getWidgetClassName } from "../utils";

const CustomCSSPopup = ({ value, onChange, widget }) => {
	const [css, setCss] = useState(value);

	const widgetClassName = getWidgetClassName(widget);

	return (
		<PopupWrapper>
			<div className="p-4 w-[80vw] max-w-[800px] max-h-[80vh] overflow-y-auto">
				{!!widgetClassName && (
					<p className="text-base font-semibold pb-2 flex items-center gap-2">
						<span>Widget Class Name: </span>
						<span className="text-gray-800 text-sm bg-gray-200 rounded-md shadow-inner py-0.5 px-2">
							{widgetClassName}
						</span>
						<button
							type="button"
							className="text-gray-800"
							onClick={() => {
								navigator.clipboard.writeText(widgetClassName);
								showToast("Copied to clipboard");
							}}>
							<FaCopy fontSize={16} />
						</button>
					</p>
				)}
				<StyleEditor
					value={css}
					style={{
						minHeight: "300px",
					}}
					onChange={(value) => {
						setCss(value);
					}}
				/>

				<div className="flex gap-2 mt-2">
					<button
						type="button"
						className="bg-gray-200 text-gray-800 p-2"
						onClick={() => {
							setCss("");
						}}>
						Clear
					</button>
					{/* submit button */}
					<button
						type="button"
						className="w-full bg-red-200 text-gray-800 p-2 gap-2 flex items-center justify-center"
						onClick={() => {
							onChange(css);
						}}>
						<span>Submit</span>
					</button>
				</div>
			</div>
		</PopupWrapper>
	);
};

const CustomCSSInput = ({ value, onChange, widget }) => {
	const dispatch = useDispatch();

	const handlePopup = () => {
		dispatch(
			setGlobalPopupElement(
				<CustomCSSPopup
					value={value}
					widget={widget}
					onChange={(data) => {
						console.log(data);
						onChange(data);
						dispatch(setGlobalPopupElement(null));
					}}
				/>
			)
		);
	};

	return (
		<button
			type="button"
			className="w-full bg-red-200 text-gray-800 p-2 rounded-md flex gap-2 items-center justify-center"
			onClick={handlePopup}>
			<FaCss3Alt className="mr-2" />
			<span>Custom CSS</span>
		</button>
	);
};

export default CustomCSSInput;
