import { useEffect, useMemo, useState } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import { findWidgetByName } from "../../utils";
import { updateWidgetItem } from "../../utils/requests";
import { showToast } from "../HomePage";
import { showToastV2 } from "../WidgetBuilder";

export interface HeaderItem {
	id: number;
	name: string;
	description: string;
	image: string;
	layouts?: string[];
}
export const headers: HeaderItem[] = [
	{
		id: 1,
		name: "Header 1",
		description: "Header with buttons",
		image: "/headers/h1.jpg",
		// layouts: [
		// 	"/headers/parts/h11.jpg",
		// 	"/headers/parts/h12.jpg",
		// 	"/headers/parts/h14.jpg",
		// 	"/headers/parts/h13.jpg",
		// ],
	},
	{
		id: 2,
		name: "Header 2",
		description: "Header with links",
		image: "/headers/h2.jpg",
	},
	{
		id: 3,
		name: "Header 3",
		description: "Header with icons",
		image: "/headers/h3.jpg",
	},
	{
		id: 4,
		name: "Header 4",
		description: "Header with search",
		image: "/headers/h4.png",
	},
];

const HeadersPage = () => {
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	// const [headerWidget, setHeaderWidget] = useState<any | null>(null);
	const [themeSettingsW, setThemeSettingsW] = useState<any | null>(null);

	useEffect(() => {
		findWidgetByName("Theme Settings").then((res) => {
			setThemeSettingsW(res);

			if (res) {
				const headerId = res?.settings?.headerId;
				const index = headers.findIndex((h) => h.id === headerId);
				setSelectedIndex(index);
			}
		});
	}, []);

	const handlePublish = () => {
		if (selectedIndex === null) return;

		const header = headers[selectedIndex];

		if (header) {
			// update header widget

			updateWidgetItem({
				...themeSettingsW,
				status: "published",
				active: true,
				settings: {
					...themeSettingsW.settings,
					headerId: header.id,
				},
			})
				.then((res) => {
					showToast("Header updated successfully");
				})
				.catch(() => {
					showToastV2("Header update failed!", "error");
				});
		}
	};

	const handleOpenSettings = (index: number) => {};

	const headersSorted = useMemo(() => {
		const index = headers.findIndex(
			(header) => header.id === themeSettingsW?.settings?.headerId
		);
		if (index > -1) {
			const selectedHeader = headers[index];
			headers.splice(index, 1);
			headers.unshift(selectedHeader);
		}
		return headers;
	}, [themeSettingsW]);

	return (
		<div>
			<div className="sticky top-[60px] left-0 z-50 bg-white py-4 w-full shadow-md px-2 flex justify-between">
				<h1 className="text-3xl font-bold">Headers</h1>

				<div className="flex gap-4 items-center">
					{/* {selectedIndex !== null && (
					)} */}
					<Link
						to={`/headers/settings`}
						className="bg-gray-200 p-2 rounded-full mr-2 group">
						<IoSettingsSharp
							size={28}
							className="group-hover:rotate-90 transition-transform"
						/>
					</Link>
					{/* publish button */}
					{selectedIndex !== null && (
						<button
							className="bg-green-500 text-white px-4 py-2 rounded-md"
							onClick={handlePublish}>
							Publish
						</button>
					)}
				</div>
			</div>
			<div className="flex flex-col gap-4 mt-4">
				{headersSorted.map((header, index) => (
					<div
						key={index}
						className="bg-white rounded-md shadow-md relative">
						<div
							className="absolute top-0 left-0 p-4 bg-white/70 hover:bg-white shadow-md transition-colors"
							style={{
								borderRadius: "0 0 100% 0",
								padding: "0.5rem 1rem 1rem 0.5rem",
							}}>
							<Checkbox
								checked={selectedIndex === index}
								onChange={() => setSelectedIndex(index)}
							/>
						</div>

						<img
							src={header.image}
							alt={header.name}
							style={{
								aspectRatio: 1920 / 600,
							}}
							onClick={() => setSelectedIndex(index)}
							className="w-full object-cover object-left-top rounded-md cursor-pointer"
						/>
						<div className="flex justify-between items-center">
							<div className="mt-4 p-4 pt-0">
								<h3 className="text-lg font-bold">
									{header.name}
								</h3>
								<p className="text-sm text-gray-500">
									{header.description}
								</p>
							</div>

							{/* {selectedIndex === index && (
								<Link
									to={`/headers/settings`}
									className="bg-gray-200 p-2 rounded-full mr-2 group">
									<IoSettingsSharp
										size={28}
										className="group-hover:rotate-90 transition-transform"
									/>
								</Link>
							)} */}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default HeadersPage;
