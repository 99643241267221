import React from "react";
import "./campaign.css";

const Campaign: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/banner.png" alt="" />
			</div>
		</div>
	);
};

export const SmallWideBanner: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/two-collum-wide.png" alt="" />
			</div>
		</div>
	);
};

export const TrendingProducts: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/trending-product.png" alt="" />
			</div>
		</div>
	);
};

export const FilterProducts: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/filter-products.png" alt="" />
			</div>
		</div>
	);
};

export const RandomBanners: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/random-banners.png" alt="" />
			</div>
		</div>
	);
};

export const ThreeBannersProducts: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/three-banner-products.png" alt="" />
			</div>
		</div>
	);
};

export const ServicesBanner: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/services.jpg" alt="" />
			</div>
		</div>
	);
};

export const VideoBanner: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/widgets/video.png" alt="" />
			</div>
		</div>
	);
};

export const CheckoutSkeleton: React.FC = () => {
	return (
		<div className="campaign__container">
			<div className="campaign__wrapper">
				<img src="/img/checkout.jpg" alt="" />
			</div>
		</div>
	);
};

export default Campaign;
