import React, { FC, useState } from "react";
import { Widget } from "../../../widgetList/widgetList";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

const FomrForSpacing: FC<Props> = ({ choosenItem, onUpdate }) => {
	const [active, setActive] = useState(false);
	const [padding, setPadding] = useState(
		(choosenItem.data as any)?.padding ?? 0
	);

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const titleObj: any = {
			name: choosenItem.name,
			active: active,
			wType: choosenItem.wType,
			id: choosenItem.id,
			data: {
				padding: padding,
			},
		};
		onUpdate(titleObj);
	};

	return (
		<form onSubmit={handleSubmit} className="mainSliderForm__container">
			<h1 className="title capitalize">{choosenItem.title}</h1>
			{/* <div className='checkbox'>
                <div>Is Active: </div>
                <input type="checkbox" defaultChecked={active} onChange={()=> setActive(!active)}/>
            </div> */}
			<div>
				<div>
					<div className="capitalize tracking-wide">
						Padding Y: (PX)
					</div>
					<input
						value={padding}
						onChange={(e) => {
							setPadding(+e.target.value);
						}}
						type="number"
						placeholder="type here...."
					/>
				</div>
			</div>
			<div>
				<button className="mainSliderForm__btn">save</button>
			</div>
		</form>
	);
};

export default FomrForSpacing;
