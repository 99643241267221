import {
	CSSProperties,
	DetailedHTMLProps,
	InputHTMLAttributes,
	useMemo,
} from "react";
import TextEditor from "../ContentEditor/TextEditor";
import { ImageItemSimple, ImageItemSimpleList } from "../ImageItem";
import CloseIcon from "../icons/CloseIcon";

export type InputTypes =
	| "text"
	| "text-list"
	| "number"
	| "select"
	| "textarea"
	| "texteditor"
	| "images"
	| "image"
	| "texteditor-list";

export type FormItems<T extends string> = {
	[key in T]?: string;
};

export interface FormItem<T> {
	name: string;
	placeholder?: string;
	type: InputTypes;
	options?: {
		value: string;
		name: string;
	}[];
	key: T;
}

interface Props<T extends string> {
	title?: string;
	data?: FormItems<T>;
	formItems: FormItem<T>[];
	onChange: (key: string, value: any) => void;
	className?: string;
	style?: CSSProperties;
	widget?: any;
}

const CustomWidgetForm = <T extends string>({
	formItems,
	data = {},
	onChange,
	title,
	className,
	style,
	widget,
}: Props<T>) => {
	const classNames = [className];

	return (
		<div className={classNames.join(" ")} style={style}>
			<div className="mainSliderForm__inputContainer">
				{title && (
					<>
						<h5 className="mainSliderForm__label">{title}</h5>
						<hr />
					</>
				)}
				<div className="mainSliderForm__input">
					{formItems.map((item, index) => (
						<FormInputItem
							key={index}
							label={item.name}
							value={data[item.key] as any}
							type={item.type}
							placeholder={item.placeholder}
							options={item.options}
							widget={widget}
							onChange={(value) => {
								onChange(item.key, value);
							}}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default CustomWidgetForm;

const FormInputItem = ({
	label,
	value,
	type,
	placeholder,
	options,
	onChange,
	widget,
}: {
	label: string;
	value: any;
	type: InputTypes;
	placeholder?: string;
	options?: {
		value: string;
		name: string;
	}[];
	onChange: (value: any) => void;
	widget?: any;
}) => {
	const uid = useMemo(() => Math.random().toString(36).substring(7), []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.value);
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		onChange(e.target.value);
	};

	const handleTextareaChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		onChange(e.target.value);
	};

	return (
		<div className="mainSliderForm__inputItem">
			<p className="mainSliderForm__inputLabel py-2">{label}</p>
			{type === "text" || type === "number" ? (
				<input
					className="mainSliderForm__inputField"
					type={type}
					value={value}
					placeholder={placeholder}
					onChange={handleChange}
				/>
			) : type === "text-list" ? (
				<InputItemList
					className="mainSliderForm__inputField"
					type={type}
					value={value || []}
					placeholder={placeholder}
					onInputChange={onChange}
				/>
			) : type === "select" ? (
				<select
					className="mainSliderForm__inputField"
					value={value}
					onChange={handleSelectChange}>
					{options?.map((option, index) => (
						<option value={option.value} key={index}>
							{option.name}
						</option>
					))}
				</select>
			) : type === "textarea" ? (
				<textarea
					className="mainSliderForm__inputField"
					value={value}
					placeholder={placeholder}
					onChange={handleTextareaChange}
				/>
			) : type === "texteditor" ? (
				<TextEditor
					key={uid}
					value={value}
					onEventChangeHandler={(data) => onChange(data)}
				/>
			) : type === "texteditor-list" ? (
				<TextEditorList value={value || []} onInputChange={onChange} />
			) : type === "images" ? (
				<ImageItemSimpleList
					images={value || []}
					onImageChange={onChange}
				/>
			) : type === "image" ? (
				<ImageItemSimple
					image={value?.image}
					title={value?.title}
					onImageChange={(image) =>
						onChange({ image, title: value?.title })
					}
					onTitleChange={(title) =>
						onChange({ image: value?.image, title })
					}
				/>
			) : null}
		</div>
	);
};

const InputItemList = ({
	value,
	onInputChange,
	...props
}: DetailedHTMLProps<
	InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & { value: string[]; onInputChange: (value: string[]) => void }) => {
	return (
		<div>
			{value?.map((item, index) => (
				<div key={index} className="flex gap-2 mb-2">
					<input
						{...props}
						value={item}
						onChange={(e) => {
							const newValue = [...value];
							newValue[index] = e.target.value;
							onInputChange(newValue);
						}}
					/>
					<button
						className=" px-1 bg-red-500 text-white rounded-md"
						onClick={() => {
							const newValue = [...value];
							newValue.splice(index, 1);
							onInputChange(newValue);
						}}>
						<CloseIcon />
					</button>
				</div>
			))}

			<div className="flex justify-center mt-4">
				<button
					onClick={() => onInputChange([...value, ""])}
					className="px-4 py-2 bg-green-500 text-white rounded-md">
					Add
				</button>
			</div>
		</div>
	);
};

const TextEditorList = ({
	value,
	onInputChange,
}: {
	value: string[];
	onInputChange: (value: string[]) => void;
}) => {
	return (
		<div>
			{value?.map((item, index) => (
				<div key={index} className="mb-3">
					<TextEditor
						key={index}
						value={item}
						onEventChangeHandler={(data) => {
							const newValue = [...value];
							newValue[index] = data;
							onInputChange(newValue);
						}}
					/>
					<button
						className="w-full mt-0.5 bg-red-500 text-white rounded-md"
						onClick={() => {
							const newValue = [...value];
							newValue.splice(index, 1);
							onInputChange(newValue);
						}}>
						Remove
					</button>
				</div>
			))}

			<div className="flex justify-center mt-4">
				<button
					onClick={() => onInputChange([...value, ""])}
					className="px-4 py-2 bg-green-500 text-white rounded-md">
					Add
				</button>
			</div>
		</div>
	);
};
