import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PermissionType } from "../../types/permission";

export interface Permissions {
	permissions: PermissionType[];
}

const initialState: Permissions = {
	permissions: [],
};

export const authPermissionsSlice = createSlice({
	name: "authPermissions",
	initialState,
	reducers: {
		getAllPermissions: (state, action: PayloadAction<PermissionType[]>) => {
			state.permissions = action.payload as PermissionType[];
		},
	},
});

export const { getAllPermissions } = authPermissionsSlice.actions;

export default authPermissionsSlice.reducer;
