import { FaCheckSquare, FaRegCheckSquare } from "react-icons/fa";

const Checkbox = ({
	checked,
	onChange,
}: {
	checked: boolean;
	onChange: (checked: boolean) => void;
}) => {
	return (
		<button onClick={() => onChange(!checked)} className="text-blue-600">
			{checked ? (
				<FaCheckSquare size={24} />
			) : (
				<FaRegCheckSquare size={24} />
			)}
		</button>
	);
};

export default Checkbox;
