import { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

function arrayMove<T>(array: readonly T[], from: number, to: number) {
	const slicedArray = array.slice();
	slicedArray.splice(
		to < 0 ? array.length + to : to,
		0,
		slicedArray.splice(from, 1)[0]
	);

	return slicedArray;
}

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement<any>((item: any) => <Photo {...item} />);
const SortableGallery = SortableContainer<any>(
	({
		items,
		remove,
	}: {
		items: string[];
		remove: (index: number) => void;
	}) => (
		<div className="flex w-full pb-2 py-4">
			{items.map((value, index) => (
				<SortablePhoto
					helperClass="!z-[9999]"
					key={`item-${(value as any)?.id || index}`}
					index={index}
					photo={value}
					removeHandler={() => remove(index)}
				/>
			))}
		</div>
	)
);

function SortImageList({
	photos,
	onUpdate,
	onRemove,
}: {
	photos: string[];
	onUpdate: (data: string[]) => void;
	onRemove: (index: number) => void;
}) {
	const [items, setItems] = useState(photos);
	useEffect(() => {
		setItems(photos);
	}, [photos]);
	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		const newItems = arrayMove(items, oldIndex, newIndex);
		setItems(newItems);
		onUpdate(newItems);
	};

	return (
		<div>
			<SortableGallery
				items={items}
				remove={onRemove}
				onSortEnd={onSortEnd}
				axis={"xy"}
			/>
		</div>
	);
}

const imgWithClick = { cursor: "pointer" };

const Photo = ({
	index,
	remove,
	removeHandler,
	onClick,
	photo,
	margin,
	direction,
	top,
	left,
}: any) => {
	const imgStyle: any = {
		margin: margin,
		// zIndex: 5000,
	};
	if (direction === "column") {
		// imgStyle.position = "absolute";
		imgStyle.left = left;
		imgStyle.top = top;
		imgStyle.width = 200;
		imgStyle.height = 200;
	}

	const handleClick = (event: any) => {
		onClick(event, { photo, index });
	};

	return (
		<div
			style={{
				color: "red",
				position: "relative",
				zIndex: 5000,
			}}
			className="relative border border-gray-300 bg-white rounded-md p-2 cursor-pointer !z-[5000] flex gap-2 justify-between items-center"
			tabIndex={0}>
			{/* <div className="absolute opacity-0 hover:opacity-100 transition-opacity bg-white/60 w-full h-full flex items-center justify-center top-0 right-0 z-10"> */}
			{/* <Image unoptimized={true}
            src={getImageUrl(photo)}
            style={onClick ? { ...imgStyle, ...(imgWithClick as any) } : imgStyle}
            alt={photo}
            width={200}
            height={200}
            objectFit='cover'
        /> */}
			<img
				style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
				// src={getImageUrl(photo)}
				src={photo}
				onClick={onClick ? handleClick : undefined}
				// className="object-cover w-[200px] h-[200px]"
				alt="img"
			/>
			{/* <button
				className="absolute p-1 rounded-full border text-xs text-black right-0 top-0 z-[90000] translate-x-full -translate-y-full"
				onClick={removeHandler}>
				<IoClose color="red" />
			</button> */}
		</div>
	);
};

export default SortImageList;
