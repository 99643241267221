import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useDispatch, useSelector } from "react-redux";
import { _axios } from "../../../api";
import { RootState } from "../../../app/store";
import { setGlobalPopupElement } from "../../../features/popupSlice";
import { adminTokenCookieName, envs } from "../../../utils/constants";
import { PageCategory, Widget } from "../../../widgetList/widgetList";
import CampaignSelector from "../../CampaignSelector";
import SortList from "../../ListSort";
import LogicBuilder from "../../LogicBuilder";
import PopupWrapper from "../../PopupWrapper";
import CloseIcon from "../../icons/CloseIcon";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

const FormForSingleProductSlider: FC<Props> = ({ choosenItem, onUpdate }) => {
	console.log("choosenItem => ", choosenItem);

	const dispatch = useDispatch();

	const customProductIds = useSelector(
		(state: RootState) => state.logicBuilder.setting
	);

	const choosenCustomProducts =
		choosenItem?.data?.singleProductSlider?.products || [];
	const [text, setText] = useState<string>("");
	const [products, setProdcuts] = useState<any[]>([]);
	const [choosenProducts, setChoosenProducts] = useState<any[]>([]);
	const [show, setShow] = useState<boolean>(true);
	const [sourceType, setSourceType] = useState("custom");

	const [selectedCampaign, setSelectedCampaign] =
		useState<PageCategory | null>(null);

	useEffect(() => {
		setChoosenProducts(choosenCustomProducts);
	}, [choosenItem]);

	useEffect(() => {
		const abortController = new AbortController();

		const timeout = setTimeout(() => {
			const apiUrl = envs.frontendApiUrl?.replace("api/v1", "api/v2");

			try {
				_axios
					.get(apiUrl + "product/storefront/search?search=" + text, {
						headers: {
							Authorization: `Bearer ${Cookies.get(
								adminTokenCookieName
							)}`,
						},
						signal: abortController.signal,
					})
					.then((res) => {
						setProdcuts(res.data?.data);
						setShow(true);
					});
			} catch (err) {
				console.error("err", err);
			}
		}, 200);

		return () => {
			clearTimeout(timeout);

			try {
				abortController.signal.throwIfAborted();

				if (abortController.signal.aborted === false)
					abortController.abort();
			} catch (error) {}
		};
	}, [text]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
		// const _text = e.target.value;
		// getProducts(_text.trim())
	};

	//   const getProducts = async(_text: string) => {
	//     checkToken()
	//     await _axios.get(envs.frontendApiUrl + 'product/storefront?search=' + _text, { headers: {
	//       "Authorization": `Bearer ${Cookies.get(adminTokenCookieName)}`
	//     }}).then(res =>{
	//       setProdcuts(res.data.data)
	//       setShow(true)
	//     })
	//   }

	const handleChoosenProduct = (product: any) => {
		const _choosenProducts = [...choosenProducts];
		_choosenProducts.push(product);
		setChoosenProducts(_choosenProducts);
		setText("");
	};

	const hideProductList = () => {
		setShow(false);
	};

	const handleRemoveProductId = (index: number) => {
		const filteredProducts = choosenProducts.filter((_, i) => i !== index);
		setChoosenProducts(filteredProducts);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const singleProductSliderProject: any = {
			name: "single-product",
			active: true,
			data: {
				singleProductSlider: {
					products: choosenProducts,
				},
				setting:
					sourceType === "custom"
						? { custom: customProductIds.custom, logical: {} }
						: { custom: [], logical: customProductIds.logical },
				selectedCampaign:
					selectedCampaign ||
					choosenItem?.data?.selectedCampaign ||
					null,
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};
		onUpdate(singleProductSliderProject);
	};

	const ref = useDetectClickOutside({ onTriggered: hideProductList });

	return (
		<>
			{choosenItem.name === "single-product" ? (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					<h1 className="title capitalize">Single Product Slider</h1>
					<button
						className="border border-blue-500 text-blue-500 font-medium px-2 py-1 rounded-md shadow-lg"
						type="button"
						onClick={() => {
							dispatch(
								setGlobalPopupElement(
									<PopupWrapper>
										<SortList
											data={choosenProducts}
											renderElement={(item) => (
												<div className="flex jusbetween items-center gap-2 select-none">
													<img
														className="rounded-md mb-1 object-contain"
														src={
															process.env
																.REACT_APP_IMAGE_URL +
															item?.thumbnail
														}
														alt=""
														width={50}
														height={50}
													/>
													<div>
														<p className="text-sm tracking-wide text-center border-b-2">
															{item?.name}
														</p>
														<p className="text-sm tracking-wide text-center border-b-2">
															{item?.slug}
														</p>
													</div>
												</div>
											)}
											updateData={(data) => {
												setChoosenProducts(data);
												dispatch(
													setGlobalPopupElement(null)
												);
											}}
										/>
									</PopupWrapper>
								)
							);
						}}>
						Sort
					</button>
					<div>
						{
							<div className="w-full my-4">
								{choosenProducts.map(
									(item: Widget, index: number) => {
										return (
											<div
												key={index}
												className="relative h-auto flex items-center justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4">
												<div className="capitalize text-xs font-light tracking-wide p-3">
													<div className="mb-2">
														<span className="font-bold">
															Product:{" "}
														</span>
														{item?.name}
													</div>
													<div>
														<span className="font-bold">
															Sku:{" "}
														</span>
														{item?.monarchmart_sku}
													</div>
												</div>
												<div
													onClick={() =>
														handleRemoveProductId(
															index
														)
													}
													className="absolute -top-3 -right-3 bg-white text-red-500 rounded-full p-1 cursor-pointer shadow-lg">
													<CloseIcon />
												</div>
											</div>
										);
									}
								)}
							</div>
						}
					</div>

					<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
						<CampaignSelector
							initialCampaign={
								choosenItem?.data?.selectedCampaign || null
							}
							onUpdate={(data) => {
								setSelectedCampaign(data);
							}}
						/>
					</div>

					<div>
						<div className="flex flex-col items-start gap-4">
							<div>Source type : </div>
							<div className="flex items-center gap-4">
								<div
									onClick={() => setSourceType("logical")}
									className="flex items-center gap-2 cursor-pointer">
									<div
										className={`w-5 h-5 rounded-md ${
											sourceType === "logical"
												? "bg-green-500 shadow"
												: "bg-gray-300 shadow-inner"
										}`}></div>
									<div>Logical</div>
								</div>
								<div
									onClick={() => setSourceType("custom")}
									className="flex items-center gap-2 cursor-pointer">
									<div
										className={`w-5 h-5 rounded-md ${
											sourceType === "custom"
												? "bg-green-500 shadow"
												: "bg-gray-300 shadow-inner"
										}`}></div>
									<div>Custom</div>
								</div>
							</div>
						</div>

						<LogicBuilder
							sourceType={sourceType}
							choosenItem={choosenItem}
						/>
					</div>
					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52">
					Please check widget name!
				</div>
			)}
		</>
	);
};

export default FormForSingleProductSlider;
