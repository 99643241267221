import { FaArrowRight } from "react-icons/fa";
import classes from "./ShowMoreButton.module.css";

interface Props {
	type: "more" | "all";
	text?: string;
}

const ShowMoreButton = ({ type, text }: Props) => {
	const typeText = type.charAt(0).toUpperCase() + type.slice(1);

	return (
		<button className={classes.showMoreButton}>
			<span>{text || `Show ${typeText}`}</span>
			<FaArrowRight className={classes.icon} />
		</button>
	);
};

export default ShowMoreButton;
