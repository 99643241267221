import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { PageCategory, Widget } from "../../../widgetList/widgetList";
import CampaignSelector from "../../CampaignSelector";
import LogicBuilder from "../../LogicBuilder";
import ProductsDefaultSettings from "../ProductsDefaultSettings";
import TopPanel, { Panel } from "../TopPanel";

interface Props {
	choosenItem: any; // type: Widget;
	widgetName?: string;
	title?: string;
	onUpdate: (w: Widget) => void;
}

const FormForPopulerProduct: FC<Props> = ({
	choosenItem,
	onUpdate,
	widgetName = "product-row-limit",
	title,
}) => {
	// const dispatch = useDispatch();
	const customProductIds = useSelector(
		(state: RootState) => state.logicBuilder.setting
	);

	const [active, setActive] = useState<boolean>(choosenItem.active);

	const [sourceType, setSourceType] = useState("");
	const [rowLimit, setrowLimit] = useState("");

	const [selectedCampaign, setSelectedCampaign] =
		useState<PageCategory | null>(null);

	const [panel, setPanel] = useState<Panel>("main");
	const [settings, setSettings] = useState<any>(
		choosenItem?.data?.settings as any
	);

	useEffect(() => {
		const customLenght: any = choosenItem?.data?.setting?.custom?.length;
		//const rowLimit:any = choosenItem?.data?.rowLimit
		const rowLimit: any = "";
		setrowLimit(rowLimit);
		if (customLenght > 0) {
			setSourceType("logical");
		} else {
			setSourceType("logical");
		}
	}, [choosenItem]);

	const handleRowLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
		setrowLimit(e.target.value.replace(/\D/g, ""));
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		console.log("customProductIds =>", customProductIds);

		const widgetObj: any = {
			name: widgetName,
			active: active,
			data: {
				setting:
					sourceType === "custom"
						? { custom: customProductIds.custom, logical: {} }
						: { custom: [], logical: customProductIds.logical },
				rowLimit: rowLimit,
				selectedCampaign:
					selectedCampaign ||
					choosenItem?.data?.selectedCampaign ||
					null,
				settings: settings,
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};
		onUpdate(widgetObj);
		//dispatch(clearSetting())
	};
	return (
		<>
			{choosenItem.name === widgetName ? (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					{title && <h1 className="title capitalize">{title}</h1>}

					<TopPanel
						panel={panel}
						onChange={(name) => setPanel(name)}
					/>

					{panel === "main" && (
						<>
							<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
								<CampaignSelector
									initialCampaign={
										choosenItem?.data?.selectedCampaign ||
										null
									}
									onUpdate={(data) => {
										setSelectedCampaign(data);
									}}
								/>
							</div>

							<h1 className="title capitalize">
								logical products column
							</h1>
							<div>
								<LogicBuilder
									sourceType={sourceType}
									choosenItem={choosenItem}
								/>
							</div>
						</>
					)}

					{panel === "settings" && (
						<ProductsDefaultSettings
							settings={settings}
							widget={choosenItem}
							onChange={(key, value) => {
								setSettings({
									...settings,
									[key]: value,
								});
							}}
						/>
					)}

					{/* <div className='border-y py-4'>
                    <p className=''>Row limit:</p>
                    <input value={rowLimit} onChange={handleRowLimit} type="text" placeholder='row limit...'/>
                </div> */}
					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52">
					Please check widget name!
				</div>
			)}
		</>
	);
};

export default FormForPopulerProduct;
