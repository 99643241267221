import React, { useEffect, useMemo, useState } from "react";
import DeleteIcon from "../../components/icons/DeleteIcon";
import EditIcon from "../../components/icons/EditIcon";

import "../HomePage/homepage.css";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import type { RootState } from "../../app/store";

import Swal from "sweetalert2";
import { CustomWidgetGet } from "../../types/widget";
import { menuWidgetNames } from "../../utils/constants";
import { createWidget, getWidgetList } from "../../utils/requests";

const sluggify = (toConvert: string): string => {
	return toConvert
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "") //remove diacritics
		.toLowerCase()
		.replace(/\s+/g, "_") //spaces to dashes
		.replace(/[^\w\-]+/g, "") //remove non-words
		.replace(/\-\-+/g, "-") //collapse multiple dashes
		.replace(/\_\_+/g, "_") //collapse multiple dashes
		.replace(/^-+/, "") //trim starting dash
		.replace(/-+$/, ""); //trim ending dash,
};

export const showToastV2 = (
	message: string,
	icon: "success" | "error" = "success"
) => {
	const Toast = Swal.mixin({
		toast: true,
		position: "bottom-end",
		showConfirmButton: false,
		timer: 2500,
	});
	Toast.fire({
		icon: icon,
		title: message,
	});
};

const WidgetBuilder: React.FC = () => {
	const permissions = useSelector(
		(state: RootState) => state.authPermission.permissions
	);

	const [widgetData, setWidgetData] = useState<CustomWidgetGet>();
	const [name, setName] = useState<string>("");

	useEffect(() => {
		getWidgetList().then(setWidgetData);
	}, []);

	const widgetItems = useMemo(
		() => (!!widgetData && !!widgetData.data ? widgetData.data : []),
		[widgetData]
	);

	const handleDeleteWidget = (id: string) => {};

	const handleCreateWidget = () => {
		createWidget({
			name,
			code: sluggify(name),
			status: "draft",
			active: true,
			is_deletable: true,
			settings: {},
		})
			.then((res: any) => {
				setWidgetData((prev) => {
					if (!prev) return prev;
					return {
						...prev,
						data: [...prev.data, res],
					};
				});
				showToastV2("Widget created successfully");
			})
			.catch(() => {
				showToastV2("Failed to create widget", "error");
			});
	};

	return (
		<div className="homepage__container">
			{/* {
            permissions.includes('create_page_layout') &&
            <div className="homepage__row--one">
            <form onSubmit={handleSumbit}>
                <input value={title} type="text" placeholder='page name...' onChange={handleOnChange}/>
                <div className='border-none shadow-lg w-full rounded-md overflow-hidden pr-2 bg-white  '>
                <select value={pageType} onChange={(e)=> setPageType(e.target.value)} name="types" id="types" className='p-3 outline-none w-full'>
                    <option value="page">Page</option>
                    <option value="campaign">Campaign</option>
                    <option value="campaign-landing">Campaign landing</option>
                    <option value="category">Category</option>
                    <option value="brand">Brand</option>
                    <option value="content">Content</option>
                </select>
                </div>
                <button className="btn" type='submit'>Add new</button>
            </form>
            </div>
        } */}
			<div className="homepage__row--two">
				<div className="w-full bg-white col-span-2 p-6 gap-6 rounded-md grid grid-cols-3">
					<div className="col-span-2 relative">
						<input type="text" placeholder="search page..." />
						{/* {
                searchText.length > 0 &&
                <div onClick={removeSearchText} className='absolute right-2 top-1 z-10 bg-white rounded-full p-1 text-red-500 shadow cursor-pointer'>
                    <CloseIcon />
                </div>
                } */}
					</div>
					<button className="btn px-6">Search</button>
					<input
						type="text"
						placeholder="Widget name..."
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<button
						type="button"
						onClick={handleCreateWidget}
						className="btn !bg-transparent border border-green-500 !text-green-500 px-6">
						Create
					</button>
				</div>
			</div>
			<div className="homepage__row--two">
				<ul>
					{widgetItems.map((widget) => {
						if (menuWidgetNames.includes(widget.name as any))
							return null;
						return (
							<li key={widget._id}>
								<div className="grid grid-cols-2 w-full">
									<div>{widget.name}</div>
									{/* <div>Type: <span className='text-sm font-light tracking-wide'>{widget.type}</span></div> */}
								</div>
								<div className="btns__wrapper">
									{/* <div className="duplicate__btn" onClick={() => handleCopyUrl(widget)}>
                            <DuplicateIcon />
                            </div> */}
									{permissions.includes(
										"update_builder_page"
									) && (
										<Link
											to={`/widgets/${widget._id}`}
											className="edit__btn">
											<EditIcon />
										</Link>
									)}
									{widget.is_deletable &&
										permissions.includes(
											"delete_builder_page"
										) && (
											<div
												className="delete__btn"
												onClick={() => {
													handleDeleteWidget(
														widget._id
													);
												}}>
												<DeleteIcon />
											</div>
										)}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
			{/* {
            showPagination &&
            <div>
            <ReactPaginate 
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={totalPage || 0}   
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}   
                onPageChange={handlePageClick}
                containerClassName={'flex items-center justify-center'}
                pageClassName={''}
                pageLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
                activeClassName={'bg-blue-500 text-white rounded-full shadow-lg'}
                previousClassName={''}
                previousLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
                breakClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
                nextClassName={''}
                nextLinkClassName={'w-10 h-10 flex items-center justify-center rounded-full'}
            />
            </div>
        } */}
		</div>
	);
};

export default WidgetBuilder;
