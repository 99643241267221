import React, { UIEvent, useEffect, useMemo, useState } from "react";
import CloseIcon from "../icons/CloseIcon";
import "./imagePopUp.css";

import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../app/store";
import {
	createFolder,
	fetchImagesModule,
	uploadImage,
} from "../../modules/mediaManager";
import { PagePagination } from "../../pages/MediaPage";
import { getImageUrl, toastMe } from "../../utils";
import ArrowIcon from "../icons/ArrowIcon";

export interface PopUpImage {
	id: number;
	url: string;
	path?: string;
}

const ImagePopUpV2 = ({
	onUpdate,
	onClose,
	single = false,
}: {
	onUpdate: (images: PopUpImage[]) => void;
	onClose: () => void;
	single?: boolean;
}) => {
	const showImagePopUpType = useSelector(
		(state: RootState) => state.showImagePopUp.type
	);
	const permissions = useSelector(
		(state: RootState) => state.authPermission.permissions
	);
	const dispatch = useDispatch();

	const [images, setImages] = useState<MediaItem[]>([]);
	const [chooseItemId, setChooseItemId] = useState(0);
	const [chooseItem, setChooseItem] = useState<boolean>(false);
	const [imagesForUpload, setImagesForUpload] = useState<any[]>([]);
	const [choosenImages, setChoosenImages] = useState<MediaItem[]>([]);
	// const [choosenImage, setChoosenImage] = useState<PopUpImage []>([])
	// const [choosenImagesForCover, setChoosenImagesForCover] = useState<PopUpImage []>([])
	// const [choosenImagesForProfile, setChoosenImagesForProfile] = useState<PopUpImage []>([])
	// const [indemandImageOne, setIndemandImageOne] = useState<PopUpImage []>([])
	// const [indemandImageTwo, setIndemandImageTwo] = useState<PopUpImage []>([])
	// const [indemandImageThree, setIndemandImageThree] = useState<PopUpImage []>([])
	// const [indemandImageFour, setIndemandImageFour] = useState<PopUpImage []>([])
	// const [featuredImage, setFeatiredImage] = useState<PopUpImage []>([])
	// const [rightImageOne, setRightImageOne] = useState<PopUpImage []>([])
	// const [rightImageTwo, setRightImageTwo] = useState<PopUpImage []>([])
	// const [rightImageThree, setRightImageThree] = useState<PopUpImage []>([])

	const [createFolderModal, setCreateFolderModal] = useState<boolean>(false);
	const [folderName, setFolderName] = useState<string>("");
	const [parentIds, setParentIds] = useState<number[]>([]);
	const parentId = useMemo(
		() => (parentIds.length > 0 ? parentIds[parentIds.length - 1] : null),
		[parentIds]
	);

	const [loading, setLoading] = useState<boolean>(false);

	const [pagePagination, setPagePagination] = useState<PagePagination>({
		total: 0,
		lastPage: 1,
		currentPage: 1,
	});

	useEffect(() => {
		getImages(pagePagination);
	}, []);

	useEffect(() => {
		const pagination = {
			total: 0,
			lastPage: 1,
			currentPage: 1,
		};

		setPagePagination(pagination);
		setImages([]);

		getImages(pagination);
	}, [parentId]);

	const getImages = async (pagination: PagePagination) => {
		setLoading(true);
		try {
			const imageList = await fetchImagesModule(pagination, parentId!);
			pagination?.total === 0
				? setImages(imageList.data)
				: setImages(images.concat(imageList.data));
			if (imageList?.totalResult) {
				const { data, ...pagination } = imageList;
				setPagePagination({
					...pagination,
					lastPage: pagination.totalPage,
					total: pagination.totalResult,
				});
			}
		} catch (error) {
			throw error;
		} finally {
			setLoading(false);
		}
	};

	const handlePageScroll = (e: UIEvent<HTMLDivElement>) => {
		const target = e.target as HTMLDivElement;

		if (
			pagePagination.currentPage < pagePagination.lastPage &&
			target.scrollTop + target.clientHeight + 120 >= target.scrollHeight
		) {
			const updatedPagination = {
				...pagePagination,
				currentPage: pagePagination.currentPage + 1,
			};
			getImages(updatedPagination);
			setPagePagination(updatedPagination);
		}
	};
	const handleChooseImage = (image: any) => {
		setChooseItemId(image?.id); // eslint-disable-next-line array-callback-return
		images.map((image) => {
			if (image?.id === chooseItemId) {
				setChooseItem(true);
			}
		});

		let _choosenImages = [...choosenImages];

		if (single) {
			_choosenImages = [];
		}

		_choosenImages.push(image);

		setChoosenImages(Array.from(new Set(_choosenImages)));

		// if(showImagePopUpType === 'cover'){
		//     setChoosenImagesForCover(_choosenImages)
		// }else if(showImagePopUpType === 'single'){
		//     setChoosenImage(_choosenImages)
		// }else if(showImagePopUpType === 'profile') {
		//     setChoosenImagesForProfile(_choosenImages)
		// }else if(showImagePopUpType === 'indemand-one'){
		//     setIndemandImageOne(_choosenImages)
		// }else if(showImagePopUpType === 'indemand-two'){
		//     setIndemandImageTwo(_choosenImages)
		// }else if(showImagePopUpType === 'indemand-three'){
		//     setIndemandImageThree(_choosenImages)
		// }else if(showImagePopUpType === 'indemand-four'){
		//     setIndemandImageFour(_choosenImages)
		// }else if(showImagePopUpType === 'featured'){
		//     setFeatiredImage(_choosenImages)
		// }else if(showImagePopUpType === 'right-one'){
		//     setRightImageOne(_choosenImages)
		// }else if(showImagePopUpType === 'right-two'){
		//     setRightImageTwo(_choosenImages)
		// }else if(showImagePopUpType === 'right-three'){
		//     setRightImageThree(_choosenImages)
		// }
		// else {
		//     setChoosenImages(_choosenImages)
		// }
	};

	const handleImagesForUpload = async (e: React.ChangeEvent) => {
		const target = e.target as HTMLInputElement;
		const items = target.files as FileList;
		const arrayOfItems = Object.values(items);
		await setImagesForUpload(arrayOfItems);
	};

	const handleUploadImage = () => {
		// eslint-disable-next-line array-callback-return
		imagesForUpload.map(async (item) => {
			setLoading(true);
			try {
				const uploadedImages = await uploadImage(item, parentId!);
				if (uploadedImages) {
					getImages({
						total: 0,
						lastPage: 1,
						currentPage: 1,
					});
				}
			} catch (error) {
				console.log(error);
				toastMe(error, "error", "Can't upload image");
			} finally {
				setLoading(false);
			}
		});
	};
	const handleInsertImages = () => {
		onUpdate(choosenImages as any);

		// if(showImagePopUpType === 'cover'){
		//     dispatch(getChoosenImages(choosenImagesForCover))
		// }else if(showImagePopUpType === 'profile') {
		//     dispatch(getChoosenImages(choosenImagesForProfile))
		// }else if(showImagePopUpType === 'single'){
		//     dispatch(getChoosenImages(choosenImage))
		// }else if(showImagePopUpType === 'indemand-one'){
		//     dispatch(getChoosenImages(indemandImageOne))
		// }else if(showImagePopUpType === 'indemand-two'){
		//     dispatch(getChoosenImages(indemandImageTwo))
		// }else if(showImagePopUpType === 'indemand-three'){
		//     dispatch(getChoosenImages(indemandImageThree))
		// }else if(showImagePopUpType === 'indemand-four'){
		//     dispatch(getChoosenImages(indemandImageFour))
		// }else if(showImagePopUpType === 'featured'){
		//     dispatch(getChoosenImages(featuredImage))
		// }else if(showImagePopUpType === 'right-one'){
		//     dispatch(getChoosenImages(rightImageOne))
		// }else if(showImagePopUpType === 'right-two'){
		//     dispatch(getChoosenImages(rightImageTwo))
		// }
		// else if(showImagePopUpType === 'right-three'){
		//     dispatch(getChoosenImages(rightImageThree))
		// }
		// else {
		//     dispatch(getChoosenImages(choosenImages))
		// }
		// dispatch(closeImagesPopUp())
	};
	const deleteChoosenImage = (id: number) => {
		const filter = choosenImages.filter((item) => item.id !== id);
		setChoosenImages(filter);
	};

	const handleCreateFolder = async () => {
		setLoading(true);
		try {
			const folder = await createFolder(folderName, parentId!);
			setFolderName("");
			if (folder) {
				getImages({
					total: 0,
					lastPage: 1,
					currentPage: 1,
				});
			}
		} catch (error) {
			console.log(error);
			toastMe(error, "error", "Can't create folder");
		} finally {
			setLoading(false);
		}

		setCreateFolderModal(false);
	};
	return (
		<div className="image__popup--container">
			<div className="image__popup--row">
				<div className="image__popup--col">
					{parentId && (
						<div className="absolute left-2 top-2 z-50">
							<button
								onClick={() => {
									setParentIds((prev) =>
										prev.slice(0, prev.length - 1)
									);
								}}
								className="flex items-center space-x-2 px-4 py-1 rounded-md bg-white shadow-md text-gray-500 hover:bg-gray-100">
								<span className="rotate-180 cursor-pointer">
									<ArrowIcon />
								</span>
								<span>Back</span>
							</button>
						</div>
					)}
					<div
						className="image__popup--wrapper"
						onScroll={handlePageScroll}>
						<div className="p-6">
							<div className="grid grid-cols-5 gap-6 pb-32">
								{/* create folder button */}
								<div
									className="w-full h-full p-2 shadow-lg relative bg-white cursor-pointer"
									onClick={() => {
										setCreateFolderModal(true);
									}}>
									<div className="w-full h-40 aspect-auto object-contain object-center bg-gray-100 cursor-pointer flex items-center justify-center">
										<img
											src="/img/folder-create.png"
											alt="folder"
											className="w-20 h-20"
										/>
									</div>
									<div className="absolute bottom-0 left-0 w-full h-10 bg-white bg-opacity-80 flex items-center justify-center">
										<p>Create Folder</p>
									</div>
								</div>
								{/* upload image button */}
								{images?.map((image: MediaItem) => {
									if (image.mimetype === "folder")
										return (
											<button
												key={image.id}
												className={`w-full h-full p-2 shadow-lg relative bg-white cursor-pointer`}
												onClick={() => {
													setParentIds((prev) => [
														...prev,
														image.id,
													]);
													// setMediaResponse(undefined);
													setPagePagination(
														(prev) => ({
															...prev,
															currentPage: 1,
														})
													);
												}}>
												{/* folder name */}
												<div className="absolute bottom-0 left-0 w-full h-10 bg-white bg-opacity-80 flex items-center justify-center">
													<p>{image.name}</p>
												</div>

												<div className="w-full h-40 aspect-auto object-contain object-center bg-gray-100 cursor-pointer flex items-center justify-center">
													<img
														src="/img/folder.svg"
														alt="folder"
														className="w-[70%] h-[60%] object-contain"
													/>
												</div>
											</button>
										);

									return (
										<div
											key={image?.id}
											className={`w-full h-full p-2 shadow-lg cursor-pointer ${
												chooseItemId === image?.id
													? "bg-blue-300"
													: "bg-white"
											}`}
											onClick={() =>
												handleChooseImage(image)
											}>
											<img
												className="w-full h-40 aspect-auto object-contain object-center bg-gray-100"
												src={getImageUrl(image?.path)}
												alt=""
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
					<div
						className="image__popup--close-btn"
						onClick={() => onClose()}>
						<CloseIcon />
					</div>
					<div className="absolute w-full h-32 bg-white bottom-0 right-0 left-0 grid grid-cols-2 gap-2 p-2 shadow">
						{permissions.includes("create_media") ? (
							<div className="bg-gray-200 grid grid-cols-2">
								<div className="w-full flex items-center justify-center p-4">
									<input
										type="file"
										accept="image/png, image/jpeg, image/svg"
										multiple
										onChange={handleImagesForUpload}
									/>
								</div>
								<div className="w-full flex items-center justify-center p-4">
									<button
										className="capitalize bg-green-500 px-8 py-3 text-white tracking-wider rounded-md shadow-lg w-full"
										onClick={handleUploadImage}>
										upload
									</button>
								</div>
							</div>
						) : (
							<div className="bg-gray-200 grid grid-cols-2"></div>
						)}
						<div className="bg-gray-200 grid grid-cols-3">
							<div className="col-span-2 flex items-center gap-2 mx-2 pr-1 overflow-x-auto w-full">
								{choosenImages.map((image) => {
									return (
										<div className="relative">
											<img
												key={image?.id}
												className="w-16 h-16 bg-white rounded-md shadow-md mr-4 last:mr-2 aspect-auto object-contain object-center"
												src={getImageUrl(image?.path)}
												alt=""
											/>
											<div
												className="image__popup--close-btn-two"
												onClick={() =>
													deleteChoosenImage(
														image?.id
													)
												}>
												<CloseIcon />
											</div>
										</div>
									);
								})}
							</div>
							<div className="flex items-center pr-4 pl-2">
								<button
									className="capitalize bg-blue-500 px-8 py-3 text-white tracking-wider rounded-md shadow-lg w-full"
									onClick={handleInsertImages}>
									insert
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* input folder with submit button modal */}
			<div
				className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 ${
					createFolderModal ? "block" : "hidden"
				}`}>
				<div
					className="absolute w-full h-full left-0 top-0 -z-10"
					onClick={() => {
						setCreateFolderModal(false);
						setFolderName("");
					}}></div>
				<div className="bg-white rounded-md shadow-lg p-6">
					<div className="flex items-center justify-between mb-4">
						<h1 className="text-xl font-bold">Create Folder</h1>
						<div
							className="cursor-pointer"
							onClick={() => {
								setCreateFolderModal(false);
								setFolderName("");
							}}>
							<CloseIcon />
						</div>
					</div>
					<div className="flex items-center justify-between gap-2">
						<input
							type="text"
							placeholder="Folder Name"
							className="w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:border-blue-500"
							value={folderName}
							onChange={(e) => setFolderName(e.target.value)}
						/>
						<button
							className="bg-blue-500 text-white px-4 py-2 rounded-md"
							onClick={handleCreateFolder}>
							Create
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ImagePopUpV2;
