import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../features/popupSlice";
import { Image } from "../widgetList/widgetList";
import TextEditor from "./ContentEditor/TextEditor";
import ImagePopUpV2 from "./ImagePopUpV2";
import { ImageContainerEditButton } from "./components";
import CloseIcon from "./icons/CloseIcon";
import LinkForm, { LinkData } from "./widgetFrom/LinkForm";

const ImageItem = ({
	title,
	image,
	index,
	setImages,
	setImage,
	onRemove,
	handleTitle = () => {},

	showDescription = false,
	setDescription = () => {},
}: {
	title?: string;
	image: Image;
	index?: number;
	setImages: React.Dispatch<React.SetStateAction<Image[]>>;
	setImage?: (image: Image) => void;
	onRemove?: () => void;
	handleTitle?: (
		e: React.ChangeEvent<HTMLInputElement>,
		image: Image | undefined
	) => void;

	showDescription?: boolean;
	description?: string;
	setDescription?: (
		e: React.ChangeEvent<HTMLInputElement>,
		image: Image | undefined
	) => void;
}) => {
	const dispatch = useDispatch();

	const handleImageSelector = (index?: number) => {
		dispatch(
			setGlobalPopupElement(
				<ImagePopUpV2
					single={index !== undefined}
					onUpdate={(images) => {
						const arrayImages = images.map((item: any) => {
							return {
								image: item.path,
								title: item.title,
								link: {} as LinkData,
							};
						});

						// setImages(prev => {
						//     return prev.concat(arrayImages);
						// })

						if (index === undefined) {
							setImages((prev) => {
								return prev.concat(arrayImages);
							});
							if (setImage) {
								setImage(arrayImages[0]);
							}
						} else {
							setImages((prev) => {
								const copyPrev = [...prev];
								copyPrev[index] = {
									...copyPrev[index],
									image: arrayImages[0].image,
								};
								return copyPrev;
							});
							if (setImage) {
								setImage({
									...image,
									image: arrayImages[0].image,
								});
							}
						}

						dispatch(setGlobalPopupElement(null));
					}}
					onClose={() => {
						dispatch(setGlobalPopupElement(null));
					}}
				/>
			)
		);
	};

	const handleLinkData = (data: LinkData, image?: any) => {
		setImages((pImages) => {
			return pImages.map((item) => {
				const copyItem = { ...item };
				if (item.image === image?.image) {
					copyItem.link = data;
				}
				return copyItem;
			});
		});

		if (setImage) {
			setImage({
				...image,
				link: data,
			});
		}
	};

	return (
		<div className="mb-4 last:mb-0 divide-y mt-2 shadow-lg p-2 bg-white rounded-md relative">
			{title && <div className="text-lg mb-4">{title}</div>}
			{/* <img className='rounded-md mb-1' src={process.env.REACT_APP_IMAGE_URL+image?.image} alt="" /> */}
			<ImageContainerEditButton
				index={index || 0}
				title={image?.title}
				imageUrl={
					!!image?.image
						? process.env.REACT_APP_IMAGE_URL + image?.image
						: ""
				}
				handleImageSelector={handleImageSelector}
			/>
			<input
				className="mb-1"
				value={image?.title}
				type="text"
				placeholder="title :"
				onChange={(e) => handleTitle(e, image)}
			/>
			{showDescription && (
				<input
					className="mb-1"
					value={image?.description || ""}
					type="text"
					placeholder="Description :"
					onChange={(e) => setDescription(e, image)}
				/>
			)}
			<LinkForm
				currnetLinkData={image?.link}
				onUpdate={handleLinkData}
				selectedImage={image}
			/>
			{!!image?.image && (
				<div className="image__popup--close-btn" onClick={onRemove}>
					<CloseIcon />
				</div>
			)}
		</div>
	);
};

export default ImageItem;

export interface SimpleImageItem {
	image: string;
	title: string;
}

export const ImageItemSimple = ({
	image,
	title,
	onImageChange,
	onTitleChange,
	onRemove,
}: {
	image: string;
	title: string;
	onImageChange: (image: string) => void;
	onTitleChange: (title: string) => void;
	onRemove?: () => void;
}) => {
	const dispatch = useDispatch();

	const handleImageSelector = (index?: number) => {
		dispatch(
			setGlobalPopupElement(
				<ImagePopUpV2
					single={index !== undefined}
					onUpdate={(images) => {
						const arrayImages = images.map((item: any) => {
							return {
								image: item.path,
								title: item.title,
								link: {} as LinkData,
							};
						});

						onImageChange(arrayImages[0].image);

						dispatch(setGlobalPopupElement(null));
					}}
					onClose={() => {
						dispatch(setGlobalPopupElement(null));
					}}
				/>
			)
		);
	};

	return (
		<div className="mb-4 last:mb-0 divide-y mt-2 py-2 relative">
			{/* remove button */}
			{!!image && (
				<div
					className="absolute top-0 right-0 p-1 bg-gray-200 rounded-full cursor-pointer z-50"
					onClick={onRemove}>
					<CloseIcon />
				</div>
			)}

			<ImageContainerEditButton
				index={0}
				title={title}
				imageUrl={image ? process.env.REACT_APP_IMAGE_URL + image : ""}
				handleImageSelector={handleImageSelector}
			/>
			{/* <input
				className="mb-1"
				value={title}
				type="text"
				placeholder="title :"
				onChange={(e) => onTitleChange(e.target.value)}
			/> */}
			<TextEditor
				value={title}
				onEventChangeHandler={(data) => onTitleChange(data)}
			/>
		</div>
	);
};

export const ImageItemSimpleList = ({
	images,
	onImageChange,
}: {
	images: SimpleImageItem[];
	onImageChange: (images: SimpleImageItem[], index: number) => void;
}) => {
	const handleImageChange = (image: string, index: number) => {
		onImageChange(
			images.map((item, i) =>
				i === index ? { image, title: item.title } : item
			),
			index
		);
	};

	const handleTitleChange = (title: string, index: number) => {
		onImageChange(
			images.map((item, i) =>
				i === index ? { image: item.image, title } : item
			),
			index
		);
	};

	const handleRemoveImage = (index: number) => {
		onImageChange(
			images.filter((item, i) => i !== index),
			index
		);
	};

	const handleAddImage = () => {
		onImageChange([...images, { image: "", title: "" }], images.length);
	};

	return (
		<div>
			{images.map((image, index) => {
				return (
					<ImageItemSimple
						key={index}
						image={image?.image}
						title={image?.title}
						onImageChange={(image) =>
							handleImageChange(image, index)
						}
						onTitleChange={(title) =>
							handleTitleChange(title, index)
						}
						onRemove={() => handleRemoveImage(index)}
					/>
				);
			})}

			<div className="flex justify-center mt-4">
				<button
					onClick={handleAddImage}
					className="px-4 py-2 bg-green-500 text-white rounded-md">
					Add Image
				</button>
			</div>
		</div>
	);
};
