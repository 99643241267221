import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { checkToken } from "../../../App";
import { _axios } from "../../../api";
import { adminTokenCookieName, envs } from "../../../utils/constants";
import { PageCategory, Widget } from "../../../widgetList/widgetList";
import CampaignSelector from "../../CampaignSelector";
import CloseIcon from "../../icons/CloseIcon";
import ProductsDefaultSettings from "../ProductsDefaultSettings";
import TopPanel, { Panel } from "../TopPanel";

interface Props {
	choosenItem: any; // type: Widget
	onUpdate: (w: Widget) => void;
}

const FormForTodaysDeal: FC<Props> = ({ choosenItem, onUpdate }) => {
	const choosenProductsForTodaysDeal = choosenItem?.data?.products || [];
	const choosenFeaturedProductForTodaysDeal =
		choosenItem?.data?.featuredProduct || {};

	const [text, setText] = useState<string>("");
	const [products, setProdcuts] = useState<any[]>([]);
	const [choosenProducts, setChoosenProducts] = useState<any[]>([]);
	const [show, setShow] = useState<boolean>(true);
	const [featuredProduct, setFeaturedProduct] = useState<any>({} as any);

	const [selectedCampaign, setSelectedCampaign] =
		useState<PageCategory | null>(null);

	const [panel, setPanel] = useState<Panel>("main");
	const [settings, setSettings] = useState<any>(
		choosenItem?.data?.settings as any
	);

	useEffect(() => {
		//getAllProducts(ids)
		setChoosenProducts(choosenProductsForTodaysDeal);
		setFeaturedProduct(choosenFeaturedProductForTodaysDeal);
	}, [choosenItem]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
		const _text = e.target.value;
		getProducts(_text.trim());
	};

	const getProducts = async (_text: String) => {
		checkToken();
		const apiUrl = envs.frontendApiUrl?.replace("api/v1", "api/v2");

		await _axios
			.get(apiUrl + "product/storefront/search?search=" + _text, {
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			})
			.then((res) => {
				setProdcuts(res.data.data);
				setShow(true);
			});
	};

	const handleChoosenProduct = (product: any) => {
		const _choosenProducts = [...choosenProducts];
		if (_choosenProducts.length < 5) {
			_choosenProducts.push(product);
		}
		setChoosenProducts(_choosenProducts);
		setText("");
	};

	const hideProductList = () => {
		setShow(false);
	};

	const handleRemoveProductId = (index: number) => {
		const filteredProducts = choosenProducts.filter((_, i) => i !== index);
		setChoosenProducts(filteredProducts);
	};

	const handleFeaturedProduct = (item: Widget) => {
		setFeaturedProduct(item);
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const widgetObj: any = {
			name: "todays-deal",
			active: true,
			data: {
				products: choosenProducts,
				featuredProduct: featuredProduct,
				selectedCampaign:
					selectedCampaign ||
					choosenItem?.data?.selectedCampaign ||
					null,
				settings: settings,
			},
			wType: choosenItem.wType,
			id: choosenItem.id,
		};
		onUpdate(widgetObj);
	};

	const ref = useDetectClickOutside({ onTriggered: hideProductList });

	return (
		<>
			{choosenItem.name === "todays-deal" ? (
				<form
					onSubmit={handleSubmit}
					className="mainSliderForm__container">
					<h1 className="text-center capitalize border-b pb-4">
						Todays Deal
					</h1>

					<TopPanel
						panel={panel}
						onChange={(name) => setPanel(name)}
					/>

					{panel === "main" && (
						<>
							<div>
								{
									<div className="w-full my-4">
										{choosenProducts.map(
											(item: Widget, index: number) => {
												return (
													<div
														key={index}
														onClick={() =>
															handleFeaturedProduct(
																item
															)
														}
														className="relative h-auto flex items-center justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4 cursor-pointer">
														<div className="capitalize text-xs font-light tracking-wide p-3">
															<div className="mb-2">
																<span className="font-bold">
																	Product:{" "}
																</span>
																{item?.name}
															</div>
															<div>
																<span className="font-bold">
																	Sku:{" "}
																</span>
																{
																	item?.monarchmart_sku
																}
															</div>
														</div>
														<div
															onClick={() =>
																handleRemoveProductId(
																	index
																)
															}
															className="absolute -top-3 -right-3 bg-white text-red-500 rounded-full p-1 cursor-pointer shadow-lg">
															<CloseIcon />
														</div>
													</div>
												);
											}
										)}
										{choosenProducts.length > 0 && (
											<div className="text-xs font-light text-center text-blue-400 mt-6">
												choose featured product from
												above...
											</div>
										)}
									</div>
								}
							</div>
							<h1 className="text-center capitalize border-b pb-4">
								Featured Product
							</h1>
							{Object.keys(featuredProduct).length > 0 && (
								<div className="h-auto flex items-left justify-center bg-white shadow-lg rounded-md mb-2 last:mb-4">
									<div className="capitalize text-xs font-light tracking-wide p-3">
										<div className="mb-2">
											<span className="font-bold">
												Product:{" "}
											</span>
											{featuredProduct?.name}
										</div>
										<div>
											<span className="font-bold">
												Sku:{" "}
											</span>
											{featuredProduct?.monarchmart_sku}
										</div>
									</div>
								</div>
							)}

							<div className="flex flex-col items-start gap-4 mt-3 pt-2 border-t">
								<CampaignSelector
									initialCampaign={
										choosenItem?.data?.selectedCampaign ||
										null
									}
									onUpdate={(data) => {
										setSelectedCampaign(data);
									}}
								/>
							</div>

							<div>
								<input
									value={text}
									type="text"
									placeholder="search..."
									onChange={handleOnChange}
								/>
								<div>
									{products.length > 0 && show && (
										<div
											className="bg-white px-3 shadow-lg divide-y max-h-96 min-h-fit overflow-y-scroll"
											ref={ref}>
											{products?.map((product) => {
												return (
													<div key={product.id}>
														<div
															className="flex flex-col items-center justify-between cursor-pointer py-2"
															onClick={() =>
																handleChoosenProduct(
																	product
																)
															}>
															<div className="text-xs font-light w-full">
																<span className="font-bold">
																	Product:
																</span>{" "}
																{product?.name}
															</div>
															<div className="text-xs font-light w-full">
																<span className="font-bold">
																	Sku:
																</span>{" "}
																{
																	product?.monarchmart_sku
																}
															</div>
															<div className="text-xs font-light flex items-center w-full justify-start">
																<span className="font-bold">
																	Price:
																</span>
																{product.discount_price ? (
																	<div>
																		<span className="text-gray-400 line-through mr-3">
																			&#2547;
																			{
																				product?.purchase_price
																			}
																		</span>
																		<span className="text-red-500">
																			&#2547;
																			{
																				product?.discount_price
																			}
																		</span>
																	</div>
																) : (
																	<div className="text-red-500">
																		{
																			product.purchase_price
																		}
																	</div>
																)}
															</div>
														</div>
													</div>
												);
											})}
										</div>
									)}
								</div>
							</div>
						</>
					)}

					{panel === "settings" && (
						<ProductsDefaultSettings
							settings={settings}
							widget={choosenItem}
							onChange={(key, value) => {
								setSettings({
									...settings,
									[key]: value,
								});
							}}
						/>
					)}

					<div>
						<button className="mainSliderForm__btn">save</button>
					</div>
				</form>
			) : (
				<div className="flex items-center justify-center text-sm font-light tracking-wide text-red-500 h-52">
					Please check widget name!
				</div>
			)}
		</>
	);
};

export default FormForTodaysDeal;
