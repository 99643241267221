import { useEffect } from "react";
import { _axios } from "./api";
import Layout from "./components/Layout";
import DashBoardPage from "./pages/DashboardPage";
import HomePage from "./pages/HomePage";
import MediaPage from "./pages/MediaPage";
import MenuDetails from "./pages/MenuDetails";
import MenuPage from "./pages/MenuPage";
import PageDetails from "./pages/PageDetails";

import { AxiosRequestHeaders } from "axios";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { getAllPermissions } from "./features/authPermissions/authPermissions";
import { setAuthShopData } from "./features/authSlice";
import FootersPage from "./pages/Footers";
import FooterSettings from "./pages/Footers/Settings";
import HeadersPage from "./pages/Headers";
import HeaderSettings from "./pages/Headers/Settings";
import LandingPages from "./pages/LandingPages";
import CustomLandingPage from "./pages/LandingPages/CustomLandingPage";
import ProductSettings from "./pages/ProductSetting";
import ThemesPage from "./pages/Themes";
import WidgetBuilder from "./pages/WidgetBuilder";
import WidgetDetails from "./pages/WidgetDetails";
import { getImageUrl } from "./utils";
import { adminTokenCookieName } from "./utils/constants";

export const checkToken = () => {
	// Cookies.set('admin-token', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InN1cGVyLXVzZXJAbW9uYXJjaG1hcnQuY29tIiwidHlwZSI6ImFkbWluIiwiaWF0IjoxNjcwMjk5NzAyLCJleHAiOjE3MDE4MzU3MDJ9.bxnsjkR_UKy1Ct3-RbH7hDrL0DpB0Orwz15dlj6Rqow', { domain: process.env.REACT_APP_COOKIE_URL });

	const token = Cookies.get(adminTokenCookieName);
	if (!token) {
		window.location.href = process.env.REACT_APP_LOGIN_REDIRECT as string;
	}
	return token;
};

export const removeToken = () => {
	// Cookies.remove('admin-token', { domain: process.env.REACT_APP_COOKIE_URL })
	// Cookies.remove(adminTokenCookieName, { domain: process.env.REACT_APP_COOKIE_URL }));
};

function App() {
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		const auth_token = checkToken();
		_axios.interceptors.request.use(
			(config) => {
				(config.headers as AxiosRequestHeaders)[
					"Authorization"
				] = `Bearer ${auth_token}`;
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);

		const authData = async () => {
			await _axios
				.get(process.env.REACT_APP_AUTH_URL + "validate-token", {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${auth_token}`,
					},
				})
				.then((res) => {
					dispatch(setAuthShopData(res.data.shop));
					dispatch(getAllPermissions(res.data.permissions));

					const favicon = res.data.shop?.favicon;

					if (favicon) {
						const link = document.querySelector(
							"link[rel='apple-touch-icon']"
						) as HTMLLinkElement;
						const link2 = document.querySelector(
							"link[rel='icon']"
						) as HTMLLinkElement;

						link.href = getImageUrl(favicon);
						link2.href = getImageUrl(favicon);
					}
				})
				.catch(() => {
					removeToken();
					// window.location.href = process.env.REACT_APP_LOGIN_REDIRECT as string;
				});
		};
		authData();
	}, [location]);
	return (
		<Layout>
			<Routes>
				<Route path="/" element={<DashBoardPage />} />
				<Route path="pages" element={<HomePage />} />
				<Route path="/widgets" element={<WidgetBuilder />} />
				<Route path="/widgets/:id" element={<WidgetDetails />} />
				<Route path="/pages/:pageTitle" element={<PageDetails />} />
				<Route path="/media" element={<MediaPage />} />
				<Route path="/menus" element={<MenuPage />} />
				<Route path="/menus/:id" element={<MenuDetails />} />
				<Route path="/headers" element={<HeadersPage />} />
				<Route path="/headers/settings" element={<HeaderSettings />} />
				<Route path="/footers" element={<FootersPage />} />
				<Route path="/footers/settings" element={<FooterSettings />} />
				<Route path="/themes" element={<ThemesPage />} />
				<Route path="/landing-pages" element={<LandingPages />} />
				<Route
					path="/landing-pages/:pageTitle"
					element={<CustomLandingPage />}
				/>

				<Route path="/product-settings" element={<ProductSettings />} />

				<Route
					path="*"
					element={
						<div className="mt-52 text-4xl font-bold flex items-center justify-center">
							<p>404! Page not found.</p>
						</div>
					}
				/>
			</Routes>
		</Layout>
	);
}

export default App;
