import { useState } from "react";

type ScreenMode = "desktop" | "mobile" | "all";

const ScreenModeSelect = ({
	onSubmit,
}: {
	onSubmit: (screenMode: ScreenMode) => void;
}) => {
	const [screenMode, setScreenMode] = useState<ScreenMode>("desktop");

	return (
		<div className="p-4 bg-white w-[80vw] max-w-[500px] max-h-[80vh] overflow-y-auto rounded-md shadow-md">
			<div className="text-lg font-semibold mb-4">Select Screen Mode</div>
			<div className="flex flex-col items-start gap-4">
				<div className="flex items-center gap-4">
					<div
						onClick={() => setScreenMode("desktop")}
						className="flex items-center gap-2 cursor-pointer">
						<div
							className={`w-5 h-5 rounded-md ${
								screenMode === "desktop"
									? "bg-green-500 shadow"
									: "bg-gray-300 shadow-inner"
							}`}></div>
						<div>Desktop</div>
					</div>
					<div
						onClick={() => setScreenMode("mobile")}
						className="flex items-center gap-2 cursor-pointer">
						<div
							className={`w-5 h-5 rounded-md ${
								screenMode === "mobile"
									? "bg-green-500 shadow"
									: "bg-gray-300 shadow-inner"
							}`}></div>
						<div>Mobile</div>
					</div>
					<div
						onClick={() => setScreenMode("all")}
						className="flex items-center gap-2 cursor-pointer">
						<div
							className={`w-5 h-5 rounded-md ${
								screenMode === "all"
									? "bg-green-500 shadow"
									: "bg-gray-300 shadow-inner"
							}`}></div>
						<div>Both (Desktop + Mobile)</div>
					</div>
				</div>
			</div>

			{/* submit button */}
			<div className="flex justify-end pt-4">
				<button
					onClick={() => onSubmit(screenMode)}
					className="px-4 py-2 bg-green-500 text-white rounded-md">
					Submit
				</button>
			</div>
		</div>
	);
};

export default ScreenModeSelect;
