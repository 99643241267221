export const SpacingCard = () => {
	return (
		<div className="title__container">
			<div className="title__wrapper">
				<img src="/img/widgets/padding.png" alt="" />
			</div>
		</div>
	);
};

export const GridCard = () => {
	return (
		<div className="title__container">
			<div className="title__wrapper">
				<img src="/img/widgets/grid.jpg" alt="" />
			</div>
		</div>
	);
};

export const TimeoutCard = () => {
	return (
		<div className="title__container">
			<div className="title__wrapper">
				<img src="/img/widgets/timeout.png" alt="" />
			</div>
		</div>
	);
};

export const ButtonCard = () => {
	return (
		<div className="title__container">
			<div className="title__wrapper">
				<img src="/img/widgets/button.jpg" alt="" />
			</div>
		</div>
	);
};
