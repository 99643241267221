import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	closeContentEditor,
	handleContentEditorData,
	handleDataSave,
} from "../../features/contentEditor/contentEditor";
import CloseIcon from "../icons/CloseIcon";
import "./contenteditor.css";
import Editor from "./editor";

import { RootState } from "../../app/store";

const ContentEditor: FC = () => {
	const content = useSelector(
		(state: RootState) => state.contentEditor.content
	);
	const [contentData, setContentData] = useState<string>(content || "");

	const dispatch = useDispatch();

	const onBoxContentChangeHandler = (newValue: string, editor: any) => {
		console.log("newValue =>", newValue);
		setContentData(newValue);
	};

	// const uploadAdapter = (loader: any) => {
	//     return {
	//         upload: () => {
	//             return new Promise((resolve, reject) => {
	//                 const body = new FormData();
	//                 loader.file.then((file: any) => {
	//                     body.append("file", file);
	//                     fetch(`${process.env.REACT_APP_MEDIA_URL}/image`, {
	//                         method: "post",
	//                         body: body
	//                     })
	//                         .then((res => res.json()))
	//                         .then((res) => {
	//                             resolve({ default: `${process.env.REACT_APP_IMAGE_URL}${res.path}` })
	//                         })
	//                         .catch((err) => {
	//                             reject(err);
	//                         })
	//                 })
	//             })
	//         }
	//     }
	// }

	// const uploadPlugin: any = (editor: any) => {
	//     editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
	//         return uploadAdapter(loader);
	//     }
	// }

	const handleSubmitContent = () => {
		dispatch(handleContentEditorData(contentData));
		dispatch(handleDataSave(true));
		if (contentData.length > 0) {
			dispatch(closeContentEditor());
		}
	};

	return (
		<div className="image__popup--container">
			<div className="image__popup--row">
				<div className="image__popup--col">
					<div className="image__popup--wrapper">
						<div className="p-6">
							{/* <CKEditorContext context={ Context }> */}
							{/* <CKEditor
                          // config={{
                          //   extraPlugins: [uploadPlugin],
                          // // builtinPlugins: [ImageRemoveEventCallbackPlugin]
                          // }}
                          editor={ ClassicEditor }
                          data={contentData}
                          onReady={ editor => {
                              // You can store the "editor" and use when it is needed.
                              console.log( 'Editor is ready to use!', editor );
                          } }
                          onChange={ ( event, editor ) => {
                              const data = editor.getData();
                              setContentData(data)
                              console.log( { event, editor, data } );
                          } }
                          onBlur={ ( event, editor ) => {
                              console.log( 'Blur.', editor );
                          } }
                          onFocus={ ( event, editor ) => {
                              console.log( 'Focus.', editor );
                          } }
                        /> */}
							{/* </CKEditorContext> */}
							<Editor
								value={contentData}
								onEventChangeHandler={onBoxContentChangeHandler}
							/>
						</div>
					</div>
					<div
						className="image__popup--close-btn"
						onClick={() => dispatch(closeContentEditor())}>
						<CloseIcon />
					</div>
					<div>
						<div
							onClick={handleSubmitContent}
							className="mainSliderForm__btn flex items-center justify-center cursor-pointer">
							save
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const ContentEditorV2 = ({
	content,
	onSubmit,
	onClose = () => {},
}: {
	content: any;
	onSubmit: (content: any) => void;
	onClose?: () => void;
}) => {
	// const content = useSelector((state: RootState) => state.contentEditor.content)
	const [contentData, setContentData] = useState<string>(content || "");

	const onBoxContentChangeHandler = (newValue: string, editor: any) => {
		console.log("newValue =>", newValue);
		setContentData(newValue);
	};

	const handleSubmitContent = () => {
		console.log("contentData", contentData);
		onSubmit(contentData);
		onClose();
		// dispatch(handleContentEditorData(contentData))
		// dispatch(handleDataSave(true))
		// if(contentData.length > 0){
		//     dispatch(closeContentEditor())
		// }
	};

	return (
		<div className="image__popup--container">
			<div className="image__popup--row">
				<div className="image__popup--col">
					<div className="image__popup--wrapper">
						<div className="p-6">
							<Editor
								value={contentData}
								onEventChangeHandler={onBoxContentChangeHandler}
							/>
						</div>
					</div>
					<div className="image__popup--close-btn" onClick={onClose}>
						<CloseIcon />
					</div>
					<div>
						<div
							onClick={handleSubmitContent}
							className="mainSliderForm__btn flex items-center justify-center cursor-pointer">
							save
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContentEditor;
