import { FC, useEffect, useMemo, useRef, useState } from "react";

import "./widgetdetails.css";

import { useSelector } from "react-redux";
import type { RootState } from "../../app/store";

import { useParams } from "react-router-dom";
import CWForm, { customFormSettings } from "../../components/CWForm";
import { CustomWidgetItem, CustomWidgetSettings } from "../../types/widget";
import { getFrontEndUrl } from "../../utils";
import { getWidgetItem, updateWidgetItem } from "../../utils/requests";
import { showToastV2 } from "../WidgetBuilder";

const formSettings: CustomWidgetSettings<any[]> = {
	banner: {
		type: "image",
		field_key: "banner",
		name: "Promotion",
		required: true,
	},
};

const WidgetDetails: FC = () => {
	const iframeRef = useRef<HTMLIFrameElement>(null);

	const permissions = useSelector(
		(state: RootState) => state.authPermission.permissions
	);
	const shop = useSelector((state: RootState) => state.auth.shop);

	const [widgetItem, setWidgetItem] = useState<CustomWidgetItem>();
	const [loading, setLoading] = useState(true);

	const [active, setActive] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		if (!!id)
			getWidgetItem(id || "")
				.then(setWidgetItem)
				.finally(() => setLoading(false));
	}, [id]);

	useEffect(() => {
		if (widgetItem) {
			// !!Object.entries(widgetItem.settings)?.[0]?.[1]?.active && setActive(true);
			setActive(!!widgetItem.active);
		}
	}, [widgetItem]);

	useEffect(() => {
		const handleLoad = () => {
			try {
				const iframe = iframeRef.current;

				if (!iframe) return;

				const rect = iframe.getBoundingClientRect();

				// Calculate the scale factor
				const scaleX = rect.width / 1920;
				const scaleY = 1;
				const scale = Math.min(scaleX, scaleY); // Ensures content fits both width and height

				iframe.style.transform = `scale(${scale})`;
				iframe.style.transformOrigin = "0 0";
				iframe.style.width = `${100 / scale}%`;
				// iframe.style.height = `${100 / scale}%`;
			} catch (error) {}
		};

		const iframe = iframeRef.current;
		if (iframe) {
			// iframe.addEventListener("load", handleLoad);
			setTimeout(() => {
				handleLoad();
			}, 500);
		}

		return () => {
			if (iframe) {
				// iframe.removeEventListener("load", handleLoad);
			}
		};
	}, []);

	// useEffect(()=> {
	//   setWidgetItem(prev => {
	//     if (prev) {
	//       const _widget = {...prev, status: 'publish', active: active};
	//       const newData = {..._widget.settings};

	//       if (newData.banner) newData.banner.active = active;
	//       if (newData.banners) newData.banners.active = active;

	//       _widget.settings = newData;
	//       return _widget;
	//     }

	//     return prev;
	//   });

	//   // setWidgetItem(prev => (prev && {...prev, status: 'draft', active: active}));
	// }, [active])

	const handlerStatus = (status: "draft" | "published") => {
		setWidgetItem((prev) => prev && { ...prev, status });
	};

	const handleSubmit = (data: CustomWidgetSettings<any>) => {
		if (widgetItem) {
			const _widget = { ...widgetItem, status: "draft", active: active };
			const newData = { ...data };

			_widget.settings = newData;

			if (!_widget.code) _widget.code = widgetItem.slug;

			updateWidgetItem(_widget)
				.then((data) => {
					setWidgetItem(data);
					showToastV2("Widget updated successfully");
				})
				.catch(() => {
					showToastV2("Something went wrong", "error");
				});

			handlerStatus("draft");
		}
	};

	const handlePublish = () => {
		if (widgetItem) {
			const _widget = { ...widgetItem };
			_widget.status = _widget.status === "draft" ? "published" : "draft";
			_widget.active = active;

			if (_widget.status === "published") {
				handlerStatus("published");

				updateWidgetItem(_widget)
					.then((data) => {
						setWidgetItem(data);
						showToastV2("Widget updated successfully");
					})
					.catch(() => {
						showToastV2("Something went wrong", "error");
					});
			} else {
				handlerStatus("draft");
			}
		}
	};

	const handleActive = () => {
		if (widgetItem) {
			const _widget = { ...widgetItem, status: "published" };
			_widget.active = !active;

			updateWidgetItem(_widget)
				.then((data) => {
					setWidgetItem(data);
					setActive(data.active);
					showToastV2("Widget updated successfully");
				})
				.catch(() => {
					showToastV2("Something went wrong", "error");
					setWidgetItem(
						(prev) =>
							prev && { ...prev, status: "draft", active: false }
					);
				});
		}
	};

	const formItems = useMemo(
		() => (!!widgetItem?.settings ? widgetItem.settings : formSettings),
		[widgetItem]
	);

	return (
		<div className="widgetdetails__container">
			<div className="widgetdetails__row--one">
				<div className="tracking-wide">{widgetItem?.name}</div>
				{permissions.includes("update_builder_page") && (
					<div className="flex gap-4">
						<div className="flex items-center gap-2">
							<div className="text-sm font-light tracking-wide">
								Publish :
							</div>
							<div
								className="coursor-pointer"
								onClick={handlePublish}>
								<div
									className={`w-12 h-fit cursor-pointer rounded-full flex items-center p-1 shadow-inner ${
										widgetItem?.status === "draft"
											? "justify-start bg-gray-400"
											: "justify-end bg-green-500"
									} transition-all`}>
									<div className="w-4 h-4 rounded-full bg-white drop-shadow-lg cursor-pointer"></div>
								</div>
							</div>
						</div>

						<div className="flex items-center gap-2">
							<div className="text-sm font-light tracking-wide">
								Live :
							</div>
							<div
								className="coursor-pointer"
								onClick={handleActive}>
								<div
									className={`w-12 h-fit cursor-pointer rounded-full flex items-center p-1 shadow-inner ${
										!active
											? "justify-start bg-gray-400"
											: "justify-end bg-green-500"
									} transition-all`}>
									<div className="w-4 h-4 rounded-full bg-white drop-shadow-lg cursor-pointer"></div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>

			<div className="widgetdetails__row--two">
				<div className="widgetdetails__col--two">
					<div className="flex items-start gap-4 w-full">
						<div className={"w-full"}>
							{/* <div className={`input__field ${true ? 'bg-gray-200' : 'bg-white'}`} onDragOver={(e) => handleOnDrop(e)}>
                  <PlusIcon />
                </div> */}
							<div className={`${false && "pt-3 mt-3 border-t"}`}>
								<div className="relative border-b pb-3 last:pb-0 mb-3 last:mb-0 last:border-none">
									<div
									// onClick={()=>{
									//     handleOnClick({...item, wType: 'widgets'}, index)
									// }}
									// onDragStart={(e) => {
									// dragItem.current = index
									// setDragItemIntoMainList(false)
									// }}
									// onDragEnter={() => {
									// handleDragEnter(item, index)
									// setDragEnter(true)
									// }}
									// onDragEnd={handleSort}
									// onDragLeave={(e) =>{
									//     e.preventDefault()
									//     const target = e.target as HTMLImageElement
									//     target.style.opacity = "1"
									// }
									// }
									// onDragOver={(e) =>{
									// e.preventDefault()
									// const target = e.target as HTMLImageElement
									//     target.style.opacity = "0.2"
									// }}
									// onDrop={(e) => handleOnDropForPushItem(e, index)}
									// draggable="true"
									>
										<div>
											<iframe
												key={JSON.stringify(widgetItem)}
												ref={iframeRef}
												title="site"
												src={getFrontEndUrl(
													shop?.slug || ""
												)}
												width="100%"
												height="1600"></iframe>
										</div>
										{/* <Banner /> */}
									</div>
									{/* <div className='absolute -top-3 -right-3 bg-white text-red-500 rounded-full drop-shadow-lg p-1 cursor-pointer'>
                            <CloseIcon />
                        </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="widgetdetails__col--three">
					<div className="indivisual_widget">
						{/* <CustomWidgetForm /> */}
						{formItems && !loading && (
							<CWForm
								title={widgetItem?.name}
								customForm={Object.keys(
									customFormSettings
								).includes(widgetItem?.name || "")}
								name={widgetItem?.name}
								formItems={formItems}
								onSubmit={handleSubmit}
							/>
						)}
						{/* <WidgetForm onUpdate={onFormUpdate} choosenItem={choosenItem}/> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default WidgetDetails;
