import { useEffect, useRef, useState } from "react";

import {
	cn,
	convertNameChar,
	getImageArray,
	getImageUrl,
	numberWithCommas,
} from "../../../utils";
import classes from "./ProductCard.module.css";

interface Props {
	productItem?: any;
	settings?: any;
}

interface ClassNameProps {
	className?: string;
}

interface PriceTagProps extends ClassNameProps {
	price: number;
	previousPrice?: number;
	reverse?: boolean;
	vat?: number;
	size?: "md" | "lg";
}

export const CardPriceTag = ({
	price,
	previousPrice,
	vat,
	className,
	reverse,
	size = "md",
}: PriceTagProps) => {
	const classNames = [classes.CardPriceTag];
	if (reverse) classNames.push(classes.reverse);
	if (size === "lg") classNames.push(classes.lg);

	return (
		<p className={`${classNames.join(" ")} ${className}`}>
			<span className={classes.price}>Tk. {numberWithCommas(price)}</span>

			{!!previousPrice && previousPrice > price && (
				<span className={classes.prePrice}>
					<span></span>
					Tk. {numberWithCommas(previousPrice)}
				</span>
			)}

			{!!vat && (
				<span className="text-xs text-text-gray4">(incl VAT)</span>
			)}
		</p>
	);
};

export const ProductTitle = ({
	title,
	className,
}: ClassNameProps & { title: string }) => {
	return (
		<h4 className={`${className} ${classes.cardTitle}`} title={title}>
			{title}
			{/* <br />{" "}<br /> */}
		</h4>
	);
};

const ProductCard = ({ productItem, settings }: Props) => {
	const ref = useRef<HTMLDivElement>(null);
	// const [rect, setRect] = useState<DOMRect | null>(null);
	// const router = useRouter();

	// const [maxMin, setMaxMin] = useState({
	//     max: false,
	//     min: false,
	// });
	// const [quantityState, setQuantity] = useState(quantity);

	// useEffect(() => {
	//     const calcSize = () => {
	//         if (ref.current) {
	//             setRect(ref.current.getBoundingClientRect());
	//         }
	//     }

	//     calcSize();

	//     window.addEventListener('resize', calcSize);

	//     return window.removeEventListener('resize', calcSize);
	// }, [])

	const [isOutOfStock, setIsOutOfStock] = useState(false);

	useEffect(() => {
		if (!!productItem?.skus?.length) {
			setIsOutOfStock(
				productItem?.skus?.every((sku: any) => sku.stockQuantity <= 0)
			);
		}
	}, [productItem?.skus]);

	const cardClasses = [classes.ProductCard, classes.sm];

	if (isOutOfStock) cardClasses.push(classes.outOfStock);

	// const handleQuantityChange = (type: '+' | '-') => {
	//     let newQuantity = quantityState;
	//     if (type === '+') {
	//         newQuantity++;
	//     } else {
	//         newQuantity--;
	//     }

	//     setQuantity(newQuantity);
	//     onQuantityChange(newQuantity);
	// }

	const product = productItem;

	if (!product) return null;

	const productUrl = `/product/${product.slug || product.id}`;

	return (
		<div ref={ref} className={cardClasses.join(" ")}>
			<div
				className={classes.cardTop}
				style={
					{
						// height: type === 'xs' && !!rect?.height ? rect?.height + 'px' : undefined,
						//   height: type === "xs" ? "100%" : undefined,
					}
				}>
				<img
					className={classes.cardImage}
					src={
						getImageUrl(
							getImageArray(product.thumbnail)[0] || ""
						) ||
						"https://scotturb.com/wp-content/uploads/2016/11/product-placeholder.jpg"
					}
					alt={product.name}
					style={{
						aspectRatio: 1,
						objectFit: "contain",
					}}
				/>

				{/* {!!productItem? && (
                    <div 
                        className={
                            "absolute left-0 top-0 bg-primary -rotate-45 flex items-end justify-center -translate-x-1/2 -translate-y-1/2 shadow-md" +
                            (type === 'xs' ? ' w-24 h-[4.6rem] text-[0.6rem]' : ' w-24 h-[5.8rem] text-[0.75rem]')
                        }
                    >
                        <p
                            className='text-white font-semibold px-2 py-1'
                        >{product.discount}% off</p>
                    </div>
                )} */}

				{/* {isOutOfStock && (
					<Link href={productUrl}>
						<div className={classes.outOfStock}>
							<p>Out of stock !</p>
						</div>
					</Link>
				)} */}
			</div>

			<div className={classes.cardBody}>
				<div className={classes.top}>
					<div className={classes.cardTextContents}>
						{/* {type !== "xs" && (
                <button className={classes.brandButton}>
                  {product.brand?.logo && (
                    <Image
                      src={getS3ImageUrl(product.brand?.logo)}
                      alt="brand"
                      layout="fill"
                      objectFit="contain"
                      className={classes.image}
                      unoptimized={true}
                    />
                  )}
                </button>
              )} */}

						<ProductTitle
							className={classes.cardTitle}
							title={convertNameChar(product.name)}
						/>

						{isOutOfStock ? (
							<p className={classes.outOfStockText}>
								Out of stock !
							</p>
						) : (
							<CardPriceTag
								className={classes.cardPriceTag}
								price={
									product.skus?.[0]?.discountedPrice ||
									product.skus?.[0]?.price ||
									0
								}
								previousPrice={product.skus?.[0]?.price || 0}
							/>
						)}

						{/* <RatingStars rating={5.0} size="sm" className="py-0.5 sm:py-2" /> */}
					</div>
				</div>

				<CardButtons />
			</div>
		</div>
		// </Link>
	);
};

export const CardButtons = ({ type = "full" }: { type?: "full" | "md" }) => {
	return (
		<div className="flex gap-2 mt-2">
			<Button
				className={`${type === "full" ? "w-full" : "px-6"} no-click ${
					classes.cardButton
				}`}
				btnType="secondary"
				onClick={() => {}}>
				Buy Now
			</Button>

			<Button
				className={`${type === "full" ? "w-full" : "px-6"} no-click ${
					classes.cardButton
				}`}
				btnType="gray"
				onClick={() => {}}>
				Add to Cart
			</Button>
		</div>
	);
};

const classNames = {
	primary:
		"bg-primary hover:bg-transparent text-white hover:text-primary border border-primary",
	secondary:
		"bg-primary/10 text-primary hover:bg-primary hover:text-primary-white",
	gray: "bg-gray4/10 text-gray4 hover:bg-gray4 hover:text-primary-white border border-transparent",
	outline:
		"bg-transparent text-primary border border-primary hover:bg-primary hover:text-primary-white",
	dark: "bg-gray3 text-primary-white hover:bg-gray3/10 hover:text-gray3",
	custom: "",
};

const Button = ({
	className,
	style,
	children,
	btnType = "primary",
	...props
}: React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
> & {
	btnType?: "primary" | "secondary" | "gray" | "custom" | "outline" | "dark";
}) => {
	return (
		<button
			className={cn(
				classNames[btnType],
				" py-1 md:py-1.5 px-0.5 text-center text-[0.75rem] md:text-[0.85rem] font-medium rounded-[3px] transition-colors duration-500",
				className
			)}
			style={style}
			{...props}>
			{children}
		</button>
	);
};

export default ProductCard;
