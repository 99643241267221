import { FC, useState } from "react";
import { FaArrowRight, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { setGlobalPopupElement } from "../../features/popupSlice";
import { Widget, widgetNames } from "../../widgetList/widgetList";
import SortList from "../ListSort";
import PopupWrapper from "../PopupWrapper";
import SettingFormItems, {
	SettingItem,
	SettingItems,
} from "../SettingFormItems";
import FormForBanner from "./FormForBanner";
import FormForBanners from "./FormForBanners";
import FormForBestSellingProdcuts from "./FormForBestSellingProducts";
import FormForBarndList from "./FormForBrandList";
import FormForButton from "./FormForButton";
import FormForCategories from "./FormForCategories";
import FormForContentEditor from "./FormForContentEditor";
import FormForDoubleCollumPromotion from "./FormForDoubleCollumPromotion";
import FormForElectronicsApplience from "./FormForElectronicsApplience";
import FormForFilterProducts from "./FormForFilterProducts";
import FormForHighlightShop from "./FormForHighlightShop";
import FormForInDemand from "./FormForInDemand";
import FormForNewArrival from "./FormForNewArrival";
import FormForPopulerProduct from "./FormForPopulerProduct";
import FormForProductRow, { FomrForProductRow2 } from "./FormForProductRow";
import FormForProductSlider from "./FormForProductSlider";
import FormForProductsLimit from "./FormForProductsLimit";
import FormForRandomSizeBanners from "./FormForRandomSizeBanners";
import FormForSellerList from "./FormForSellerList";
import FormForSellerProfile from "./FormForSellerProfile";
import FormForServices from "./FormForServices";
import FormForSingleCollumPromotion from "./FormForSingleCollumPromotion";
import FormForSingleProductSlider from "./FormForSingleProductSlider";
import FromForSlider from "./FormForSlider";
import FormForSmallWideBanner from "./FormForSmallWideBanner";
import FomrForSpacing from "./FormForSpacing";
import FormForThreeBannerProducts from "./FormForThreeBannerProducts";
import FormForThreeCollumBanner from "./FormForThreeCollum";
import FormForTimeout from "./FormForTimeout";
import FomrForTile from "./FormForTitle";
import FormForTodaysDeal from "./FormForTodaysDeal";
import FormForVideoSection from "./FormForVideoSection";
import FromForTrendingProducts from "./FromForTrendingProducts";

interface Props {
	choosenItem: Widget;
	onUpdate: (w: Widget) => void;
}

const WidgetForm: FC<Props> = ({ choosenItem, onUpdate }) => {
	console.log("widgetForm choosenItem :>> ", choosenItem);
	return (
		<>
			{choosenItem.name === "slider" ? (
				<FromForSlider onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === "banner" ? (
				<FormForBanner onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === "3-collum-images" ? (
				<FormForThreeCollumBanner
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "populer-product" ? (
				<FormForPopulerProduct
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "title" ? (
				<FomrForTile onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === "categories" ? (
				<FormForCategories
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "one-collum-add" ? (
				<FormForSingleCollumPromotion
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "two-collum-add" ? (
				<FormForDoubleCollumPromotion
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "product-row" ? (
				<FormForProductRow
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "brand-list" ? (
				<FormForBarndList
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "seller-list" ? (
				<FormForSellerList
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "new-arrival" ? (
				<FormForNewArrival
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "product-slider" ? (
				<FormForProductSlider
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "todays-deal" ? (
				<FormForTodaysDeal
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "highlighted-shop" ? (
				<FormForHighlightShop
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "best-selling" ? (
				<FormForBestSellingProdcuts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "single-product" ? (
				<FormForSingleProductSlider
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "electronics-applience" ? (
				<FormForElectronicsApplience
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "seller-profile" ? (
				<FormForSellerProfile
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "content-editor" ? (
				<FormForContentEditor
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "product-row-limit" ? (
				<FormForProductsLimit
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === "in-demand" ? (
				<FormForInDemand
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.lastVisitedProducts ? (
				<FomrForProductRow2
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.infiniteProductRow ? (
				<FormForProductsLimit
					widgetName={widgetNames.infiniteProductRow}
					title="Infinite Product Row"
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.smallWideBanner ? (
				<FormForSmallWideBanner
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.banners ? (
				<FormForBanners onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === widgetNames.trendingProducts ? (
				<FromForTrendingProducts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.filterProducts ? (
				<FormForFilterProducts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.threeBannerProducts ? (
				<FormForThreeBannerProducts
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.randomSizeBanners ? (
				<FormForRandomSizeBanners
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.services ? (
				<FormForServices
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.videoSection ? (
				<FormForVideoSection
					widgetName={widgetNames.infiniteProductRow}
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.padding ? (
				<FomrForSpacing onUpdate={onUpdate} choosenItem={choosenItem} />
			) : choosenItem.name === widgetNames.timeout ? (
				<FormForTimeout
					widgetName={widgetNames.timeout}
					onUpdate={onUpdate}
					choosenItem={choosenItem}
				/>
			) : choosenItem.name === widgetNames.grid ? (
				<GridWidgetForm choosenItem={choosenItem} onUpdate={onUpdate} />
			) : choosenItem.name === widgetNames.button ? (
				<FormForButton onUpdate={onUpdate} choosenItem={choosenItem} />
			) : null}
		</>
	);
};

export default WidgetForm;

type SettingsNames =
	| "bgColor"
	| "bgImage"
	| "bgSize"
	| "bgPosition"
	| "bgRepeat"
	| "bgAttachment"
	| "padding";

const settingItems: SettingItem<SettingsNames>[] = [
	{
		key: "bgColor",
		name: "Background Color",
		type: "color",
	},
	{
		key: "bgImage",
		name: "Background Image",
		type: "image",
	},
	{
		key: "bgSize",
		name: "Background Size",
		type: "select",
		options: [
			{
				name: "Cover",
				value: "cover",
			},
			{
				name: "Contain",
				value: "contain",
			},
		],
	},
	{
		key: "bgPosition",
		name: "Background Position",
		type: "select",
		options: [
			{
				name: "Top",
				value: "top",
			},
			{
				name: "Center",
				value: "center",
			},
			{
				name: "Bottom",
				value: "bottom",
			},
		],
	},
	{
		key: "bgRepeat",
		name: "Background Repeat",
		type: "select",
		options: [
			{
				name: "No Repeat",
				value: "no-repeat",
			},
			{
				name: "Repeat",
				value: "repeat",
			},
			{
				name: "Repeat X",
				value: "repeat-x",
			},
			{
				name: "Repeat Y",
				value: "repeat-y",
			},
		],
	},
	{
		key: "bgAttachment",
		name: "Background Attachment",
		type: "select",
		options: [
			{
				name: "Fixed",
				value: "fixed",
			},
			{
				name: "Scroll",
				value: "scroll",
			},
		],
	},
	{
		key: "padding",
		name: "Padding",
		type: "text",
	},
];

type Panel = "main" | "settings";
const panels: {
	name: Panel;
	title: string;
}[] = [
	{
		name: "main",
		title: "Main",
	},
	{
		name: "settings",
		title: "Settings",
	},
];

const GridWidgetForm: FC<Props> = ({ choosenItem, onUpdate }) => {
	const dispatch = useDispatch();
	const [selectedWidget, setSelectedWidget] = useState<Widget | null>(null);
	const [panel, setPanel] = useState<Panel>("main");
	const [settings, setSettings] = useState<SettingItems<SettingsNames>>(
		choosenItem.data?.settings || {}
	);

	const handleSaveWidget = () => {
		const newData = {
			...choosenItem,
			data: {
				...choosenItem.data,
				settings,
			},
		};

		onUpdate(newData);
	};

	const widgets = choosenItem?.data?.widgets || [];

	if (!widgets.length) return <div>Add Widgets</div>;

	return (
		<div className="w-full flex flex-col pt-4 mt-4 mainSliderForm__container">
			{/* panels switch */}
			<div className="flex flex-col md:flex-row border border-black/5 border-b-none mt-0 pb-2">
				{panels.map((p) => (
					<button
						key={p.name}
						type="button"
						className={`flex-1 py-2 text-sm font-light tracking-wide text-center border-b-2 focus:outline-none ${
							panel === p.name
								? "border-green-500 text-black"
								: "text-black/50 hover:text-black/70"
						}`}
						onClick={() => setPanel(p.name)}>
						{p.title}
					</button>
				))}
			</div>

			{panel === "settings" && (
				<SettingFormItems
					settingItems={settingItems}
					settings={settings}
					onChange={(key, value) => {
						setSettings((prev) => {
							return {
								...prev,
								[key]: value,
							};
						});
					}}
				/>
			)}

			{panel === "main" && (
				<>
					{selectedWidget && (
						<button
							className="mb-4 px-2 py-1 rounded-md bg-gray-200 hover:bg-gray-300 transition-colors duration-300"
							onClick={() => {
								setSelectedWidget(null);
							}}>
							Back
						</button>
					)}
					{selectedWidget && (
						<WidgetForm
							choosenItem={selectedWidget}
							onUpdate={(w) => {
								console.log("w :>> ", w);

								const newData = {
									...choosenItem,
									data: {
										...choosenItem.data,
										settings,
										widgets:
											choosenItem?.data?.widgets?.map(
												(widget) => {
													console.log(
														"widget :>> ",
														widget
													);
													if (
														widget.id &&
														widget.id === w.id
													) {
														return w;
													}
													return widget;
												}
											),
									},
								};

								onUpdate(newData);
							}}
						/>
					)}

					{!selectedWidget && (
						<button
							className="bg-blue-500 text-white px-2 py-1 rounded-md shadow-lg mb-4"
							type="button"
							onClick={() => {
								dispatch(
									setGlobalPopupElement(
										<PopupWrapper>
											<SortList
												data={widgets}
												renderElement={(item) => (
													<p>{item.name}</p>
												)}
												updateData={(data) => {
													const newData = {
														...choosenItem,
														data: {
															...choosenItem.data,
															widgets: data,
														},
													};

													onUpdate(newData);

													dispatch(
														setGlobalPopupElement(
															null
														)
													);
												}}
											/>
										</PopupWrapper>
									)
								);
							}}>
							Sort
						</button>
					)}

					{!selectedWidget &&
						widgets.map((w: Widget, index: number) => {
							return (
								<div key={index} className="flex gap-2 w-full">
									<button
										className="flex-1 mb-4 px-2 py-1 rounded-md bg-gray-200 hover:bg-gray-300 transition-colors duration-300 flex gap-2 justify-center items-center"
										onClick={() => {
											setSelectedWidget(w);
										}}>
										<p>{w.name}</p>
										<FaArrowRight
											color="gray"
											fontSize={18}
										/>
									</button>

									<button
										className="mb-4 px-2 py-1 rounded-md bg-red-200 hover:bg-red-300 transition-colors duration-300 flex gap-2 justify-center items-center"
										onClick={() => {
											const newData = {
												...choosenItem,
												data: {
													...choosenItem.data,
													widgets:
														choosenItem?.data?.widgets?.filter(
															(widget) => {
																return (
																	widget.id !==
																	w.id
																);
															}
														),
												},
											};

											onUpdate(newData);
										}}>
										<FaTrash color="red" fontSize={18} />
									</button>
								</div>
							);
						})}
				</>
			)}

			<div className="pt-4">
				<button
					type="button"
					className="mainSliderForm__btn"
					onClick={handleSaveWidget}>
					save
				</button>
			</div>
		</div>
	);
};
