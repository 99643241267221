import Cookies from "js-cookie";
import { checkToken } from "../App";
import { _axios } from "../api";
import { Image, PagePagination } from "../pages/MediaPage";
import { PageCategory } from "../widgetList/widgetList";
import { CreatedMenu } from "./../features/addMenu";
import { Page } from "./../features/addPages/addPages";
import { adminTokenCookieName } from "./../utils/constants";

export const fetchImagesModule = async (
	pagePagination: PagePagination,
	parentId?: number
) => {
	checkToken();
	try {
		const res = await _axios.get(
			process.env.REACT_APP_MEDIA_URL +
				`${!!parentId ? "" + parentId : ""}?currentPage=${
					pagePagination.currentPage
				}&perPage=25`,
			{
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			}
		);
		return res.data;
	} catch (error) {
		throw error;
	}
};

export const uploadImage = async (image: File, parentId?: number) => {
	checkToken();
	try {
		const fd = new FormData();
		fd.append("file", image);
		if (!!parentId) fd.append("parentId", (parentId || null) as any);
		const newImage: Image = await _axios.post(
			process.env.REACT_APP_MEDIA_URL + "",
			fd,
			{
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			}
		);
		return newImage;
	} catch (error) {
		throw error;
	}
};

export const createFolder = async (name: string, parentId?: number) => {
	checkToken();
	try {
		const res = await _axios.post(
			process.env.REACT_APP_MEDIA_URL + "create-folder",
			{
				name,
				parentId: parentId || null,
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			}
		);

		return res.data;
	} catch (error) {
		throw error;
	}
};

export const deleteImage = async (
	id: number,
	pagePagination: PagePagination
) => {
	checkToken();
	try {
		await _axios.delete(process.env.REACT_APP_MEDIA_URL + "" + id, {
			headers: {
				Authorization: `Bearer ${Cookies.get(adminTokenCookieName)}`,
			},
		});
		return fetchImagesModule(pagePagination);
	} catch (error) {
		throw error;
	}
};

export const updateAllPages = async (
	page: Page,
	widget: any,
	type: "widgets" | "sidebar" | "topbar" = "widgets",
	categories: PageCategory[] = [],
	brands: PageCategory[] = [],
	campaigns: PageCategory[] = [],
	products: PageCategory[] = []
) => {
	console.log("test test tes tes =>", page);
	checkToken();
	let res;
	try {
		res = await _axios.patch(
			process.env.REACT_APP_BUILDER_URL + "page/" + page._id,
			{
				status: page.status,
				categories: categories,
				brands: brands,
				campaigns: campaigns,
				products: products,
				change_log: page.change_log,
				settings: page.settings,
				screenMode: page.screenMode,
				[type]: widget,
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			}
		);
	} catch (error) {
		throw error;
	}

	return res;
};

export const updatePageData = async (page: Page): Promise<Page> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await _axios.patch(
				process.env.REACT_APP_BUILDER_URL + "page/" + page._id,
				{
					status: page.status,
					categories: page.categories,
					brands: page.brands,
					campaigns: page.campaigns,
					change_log: page.change_log,
					widgets: page.widgets,
					sidebar: page.sidebar,
					topbar: page.topbar,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const updatePageStatus = async (
	page: Page,
	status: "draft" | "publish"
): Promise<Page> => {
	return new Promise(async (resolve, reject) => {
		try {
			const res = await _axios.patch(
				process.env.REACT_APP_BUILDER_URL + "page/" + page._id,
				{
					status: status,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);

			resolve(res.data);
		} catch (error) {
			reject(error);
		}
	});
};

export const updateMenu = async (menu: CreatedMenu) => {
	checkToken();
	let res;
	try {
		if (menu._id) {
			res = await _axios.patch(
				process.env.REACT_APP_BUILDER_URL + "menu/" + menu._id,
				{
					name: menu.name,
					slug: menu.slug,
					status: menu.status,
					menu: menu.menu,
				},
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${Cookies.get(
							adminTokenCookieName
						)}`,
					},
				}
			);
		}
	} catch (error) {
		throw error;
	}

	return res;
};

export const getPageDetails = async (id: any) => {
	console.log("id =>", id);
	checkToken();

	let res;

	try {
		res = await _axios.get(
			process.env.REACT_APP_BUILDER_URL + "page/" + id,
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${Cookies.get(
						adminTokenCookieName
					)}`,
				},
			}
		);
	} catch (error) {
		throw error;
	}

	return res;
};
