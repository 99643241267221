import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { HiDotsHorizontal } from "react-icons/hi";
import { getImageUrl } from "../../../utils";
import { RenderWidgetProps } from "../../../widgetList/widgetList";
import ThreeCollumsImages from "../../Widgets/ThreeCollumsImages";

import MainSlider from "../../Widgets/MainSlider";
import classes from "./ShowBannersSection.module.css";

interface BannerDataType {
	title: string;
	image: string;
	link: string;
	wide: boolean;
}

export const ShowBannersSlider = ({ widget }: RenderWidgetProps) => {
	return <ShowBannersSection widget={widget} isSlider={true} />;
};

const ShowBannersSection = ({
	widget,
	isSlider,
}: RenderWidgetProps & {
	isSlider?: boolean;
}) => {
	const images = widget?.data?.images || [];
	const settings = widget?.data?.settings || {};

	const col = settings?.col || (isSlider ? 1 : 3);
	const gap = settings?.gap || 0;
	const width = settings?.width || (isSlider ? 1920 : 300);
	const height = settings?.height || (isSlider ? 800 : 300);

	const slider = isSlider || settings?.isSlider;

	const classNames = [classes.ShowBannersSection, classes["col" + col]];

	const imagesData = isSlider && images.length > 0 ? [images[0]] : images;

	if (imagesData.length === 0) {
		if (isSlider) return <MainSlider />;
		else return <ThreeCollumsImages />;
	}

	return (
		<div className={classNames.join(" ")}>
			<div
				className={
					settings?.containerType === "full" ? "" : "no-container"
				}>
				<div
					className={classes.contents}
					style={
						{
							"--col": col || 3,
							"--col-md": Math.min(col || 3, 3),
							gap: `${gap}px`,
						} as React.CSSProperties
					}>
					{slider && (
						<div className="absolute left-0 right-0 w-full h-full flex justify-between items-center z-10">
							<FaAngleLeft
								fontSize={32}
								color="white"
								className="drop-shadow-md"
							/>
							<div className="flex flex-col justify-end h-full">
								<HiDotsHorizontal fontSize={32} />
							</div>
							<FaAngleRight
								fontSize={32}
								color="white"
								className="drop-shadow-md"
							/>
						</div>
					)}
					{imagesData.map((banner, index) => {
						let classNames = `${classes.banner}`;

						return (
							<div
								className={classNames}
								key={index}
								title={"banner.title"}>
								<img
									src={getImageUrl(banner.image)}
									alt={banner.title}
									style={{
										aspectRatio: width / height,
										objectFit:
											settings?.objectFit || "contain",
									}}
								/>
								{/* <Link href={banner.link?.url || '#'} passHref> */}
								{/* <a className={!banner.link?.url ? 'disabled-link' : ''}> */}
								{/* <Image
                                        src={banner.image}
                                        alt={banner.title || "Banner"}
                                        blurDataURL={"https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png"}
                                        placeholder="blur"
                                        layout="responsive"
                                        width={width}
                                        height={height}
                                        objectFit={settings?.objectFit || "contain"}
                                        unoptimized={true}
                                    /> */}
								{/* </a> */}
								{/* </Link> */}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default ShowBannersSection;
