import { useEffect, useMemo, useState } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import Checkbox from "../../components/Checkbox";
import { findWidgetByName } from "../../utils";
import { updateWidgetItem } from "../../utils/requests";
import { showToastV2 } from "../WidgetBuilder";

const footers = [
	{
		id: 1,
		name: "Footer 1",
		description: "Footer with buttons",
		image: "/footers/f1.jpg",
	},
	{
		id: 2,
		name: "Footer 2",
		description: "Footer with links",
		image: "/footers/f2.jpg",
	},
	{
		id: 3,
		name: "Footer 3",
		description: "Footer with icons",
		image: "/footers/f3.jpg",
	},
	{
		id: 4,
		name: "Footer 4",
		description: "Footer with icons",
		image: "/footers/f4.jpg",
	},
];

const FootersPage = () => {
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const [themeSettingsW, setThemeSettingsW] = useState<any | null>(null);

	useEffect(() => {
		findWidgetByName("Theme Settings").then((res) => {
			setThemeSettingsW(res);

			if (res) {
				const footerId = res?.settings?.footerId;
				const index = footers.findIndex((h) => h.id === footerId);
				setSelectedIndex(index);
			}
		});
	}, []);

	const handlePublish = () => {
		if (selectedIndex === null) return;

		const footer = footers[selectedIndex];

		if (footer) {
			// update header widget

			updateWidgetItem({
				...themeSettingsW,
				status: "published",
				active: true,
				settings: {
					...themeSettingsW.settings,
					footerId: footer.id,
				},
			})
				.then((res) => {
					showToastV2("Footer updated successfully");
				})
				.catch(() => {
					showToastV2("Footer update failed!", "error");
				});
		}
	};

	const footersSorted = useMemo(() => {
		const index = footers.findIndex(
			(footer) => footer.id === themeSettingsW?.settings?.footerId
		);
		if (index > -1) {
			const selectedFooter = footers[index];
			footers.splice(index, 1);
			footers.unshift(selectedFooter);
		}
		return footers;
	}, [themeSettingsW]);

	return (
		<div>
			<div className="sticky top-[60px] left-0 z-50 bg-white py-4 w-full shadow-md px-2 flex justify-between">
				<h1 className="text-3xl font-bold">Footers</h1>

				<div className="flex gap-4 items-center">
					{/* publish button */}
					{selectedIndex !== null && (
						<button
							className="bg-green-500 text-white px-4 py-2 rounded-md"
							onClick={handlePublish}>
							Publish
						</button>
					)}

					<Link
						to={`/footers/settings`}
						className="bg-gray-200 p-2 rounded-full mr-2 group">
						<IoSettingsSharp
							size={28}
							className="group-hover:rotate-90 transition-transform"
						/>
					</Link>
				</div>
			</div>

			<div className="grid grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
				{footersSorted.map((header, index) => (
					<div
						key={index}
						className="bg-white rounded-md shadow-md relative">
						<div
							className="absolute top-0 left-0 p-4 bg-white/70 hover:bg-white shadow-md transition-colors"
							style={{
								borderRadius: "0 0 100% 0",
								padding: "0.5rem 1rem 1rem 0.5rem",
							}}>
							<Checkbox
								checked={selectedIndex === index}
								onChange={() => setSelectedIndex(index)}
							/>
						</div>

						<img
							src={header.image}
							alt={header.name}
							style={{
								aspectRatio: 1920 / 1600,
							}}
							onClick={() => setSelectedIndex(index)}
							className="w-full object-cover object-top rounded-md cursor-pointer"
						/>
						<div className="mt-4 p-4 pt-0">
							<h3 className="text-lg font-bold">{header.name}</h3>
							<p className="text-sm text-gray-500">
								{header.description}
							</p>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default FootersPage;
